@import "../../../styles/lib/variables";

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

p {
  letter-spacing: 0.3px;
}

@mixin heading-media-queries(
  $mobile_font-size,
  $mobile_line-height,
  $tablet_font-size,
  $tablet_line-height,
  $desktop_font-size,
  $desktop_line-height
) {
  font-size: $mobile_font-size;
  line-height: $mobile_line-height;
  @media (min-width: 992px) {
    font-size: $tablet_font-size;
    line-height: $tablet_line-height;
  }
  @media (min-width: 1200px) {
    font-size: $desktop_font-size;
    line-height: $desktop_line-height;
  }
}

h1 {
  font-family: $h1__font-family;
  font-weight: $font-weight__bold;
  margin-top: 0;
  margin-bottom: 20px;
  @include heading-media-queries(
    $mobile_font-size: 48px,
    $mobile_line-height: 48px,
    $tablet_font-size: 60px,
    $tablet_line-height: 60px,
    $desktop_font-size: 80px,
    $desktop_line-height: 76px
  );
}

h2 {
  font-family: $h1__font-family;
  font-weight: $font-weight__bold;
  letter-spacing: 0.4px;
  margin-top: 0;
  margin-bottom: 20px;
  @include heading-media-queries(
    $mobile_font-size: 40px,
    $mobile_line-height: 40px,
    $tablet_font-size: 48px,
    $tablet_line-height: 48px,
    $desktop_font-size: 60px,
    $desktop_line-height: 56px
  );
}

h3 {
  font-family: $h1__font-family;
  font-weight: $font-weight__bold;
  letter-spacing: 0.56px;
  margin-top: 0;
  margin-bottom: 20px;
  @include heading-media-queries(
    $mobile_font-size: 28px,
    $mobile_line-height: 28px,
    $tablet_font-size: 32px,
    $tablet_line-height: 32px,
    $desktop_font-size: 36px,
    $desktop_line-height: 36px
  );
}

h4 {
  font-family: $font-family__base;
  font-weight: $font-weight__extrabold;
  letter-spacing: 0.18px;
  margin-top: 0;
  margin-bottom: 20px;
  @include heading-media-queries(
    $mobile_font-size: 18px,
    $mobile_line-height: 20px,
    $tablet_font-size: 20px,
    $tablet_line-height: 24px,
    $desktop_font-size: 24px,
    $desktop_line-height: 28px
  );
}

h5 {
  font-family: $font-family__base;
  font-weight: $font-weight__bold;
  letter-spacing: 0.16px;
  margin-top: 0;
  margin-bottom: 20px;
  @include heading-media-queries(
    $mobile_font-size: 16px,
    $mobile_line-height: 20px,
    $tablet_font-size: 18px,
    $tablet_line-height: 20px,
    $desktop_font-size: 20px,
    $desktop_line-height: 24px
  );
}

h6 {
  font-family: $font-family__base;
  font-weight: $font-weight__bold;
  letter-spacing: 0.28px;
  margin-top: 0;
  margin-bottom: 20px;
  @include heading-media-queries(
    $mobile_font-size: 14px,
    $mobile_line-height: 20px,
    $tablet_font-size: 15px,
    $tablet_line-height: 20px,
    $desktop_font-size: 16px,
    $desktop_line-height: 24px
  );
}
.h6 {
  font-family: $font-family__base;
  font-weight: $font-weight__bold;
  letter-spacing: 0.28px;
  margin-top: 0;
  margin-bottom: 20px;
  @include heading-media-queries(
    $mobile_font-size: 14px,
    $mobile_line-height: 20px,
    $tablet_font-size: 15px,
    $tablet_line-height: 20px,
    $desktop_font-size: 16px,
    $desktop_line-height: 24px
  );
}

.label {
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

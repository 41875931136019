.BrainhubCarouselItem {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.BrainhubCarouselItem.BrainhubCarouselItem--clickable {
  cursor: pointer;
}
.BrainhubCarouselItem .debug-number {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  font-size: 2em;
  text-shadow: 0px 0px 9px white;
}
.BrainhubCarousel {
  overflow: hidden;
  display: flex;
  align-items: center;
}
.BrainhubCarousel.BrainhubCarousel--isRTL {
  direction: rtl;
}
.BrainhubCarousel.BrainhubCarousel--isRTL
  .BrainhubCarousel__trackContainer
  .BrainhubCarousel__track {
  direction: rtl;
}
.BrainhubCarousel .BrainhubCarousel__trackContainer {
  overflow: hidden;
}
.BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
  display: flex;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0;
}
.BrainhubCarousel
  .BrainhubCarousel__trackContainer
  .BrainhubCarousel__track.BrainhubCarousel__track--transition {
  transition: transform;
}
.BrainhubCarousel__arrows {
  cursor: pointer;
}
.BrainhubCarousel__dots {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
}
.BrainhubCarousel__dots.BrainhubCarousel__dots--isRTL {
  direction: rtl;
}
.BrainhubCarousel__dots .BrainhubCarousel__dot {
  outline: 0;
  padding: 10px;
  border: none;
  opacity: 0.5;
  cursor: pointer;
  -webkit-appearance: none;
}
.BrainhubCarousel__dots .BrainhubCarousel__dot.BrainhubCarousel__dot--selected {
  opacity: 1 !important;
}
.BrainhubCarousel__dots .BrainhubCarousel__dot:hover {
  opacity: 1;
}
.BrainhubCarousel__dots .BrainhubCarousel__dot:before {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  padding: 0;
  border: none;
  background: #000;
}
.BrainhubCarousel__dots .BrainhubCarousel__thumbnail {
  outline: 0;
  padding: 10px;
  border: none;
  opacity: 0.5;
  cursor: pointer;
}
.BrainhubCarousel__dots
  .BrainhubCarousel__thumbnail.BrainhubCarousel__thumbnail--selected {
  opacity: 1 !important;
}
.BrainhubCarousel__dots .BrainhubCarousel__thumbnail:hover {
  opacity: 1;
}
.BrainhubCarousel__thumbnail[type="button"] {
  -webkit-appearance: none;
}
.BrainhubCarousel--isRTL + .BrainhubCarousel__dots {
  direction: rtl;
}
.BrainhubCarousel__arrows {
  position: relative;
  padding: 21px;
  border: none;
  overflow: hidden;
  outline: 0;
  font-size: 0;
  line-height: 0;
  background-color: #7b59ff;
}
.BrainhubCarousel__arrows span {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  border-style: solid;
  border-color: #fff;
  border-width: 3px 3px 0 0;
  padding: 5px;
  transition: 0.3s;
  font-size: 0;
}
.BrainhubCarousel__arrows:hover {
  background-color: #8768ff;
}
.BrainhubCarousel__arrows:hover span {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  border-style: solid;
  border-color: #fff;
  border-width: 3px 3px 0 0;
  padding: 5px;
  transition: 0.3s;
  font-size: 0;
}
.BrainhubCarousel__arrows:hover:enabled {
  background-color: #8768ff;
}
.BrainhubCarousel__arrows:hover:enabled span {
  border-color: #fff;
  margin: 0;
}
.BrainhubCarousel__arrows:disabled {
  background-color: #ccc;
}
.BrainhubCarousel__arrowLeft span {
  transform: translate(-50%, -50%) rotate(-135deg);
  margin-left: 2.45px;
}
.BrainhubCarousel__arrowRight span {
  transform: translate(-50%, -50%) rotate(45deg);
  margin-left: -2.45px;
}
.BrainhubCarousel--isRTL .BrainhubCarousel__arrowLeft span {
  transform: translate(-50%, -50%) rotate(45deg);
  margin-left: -2.45px;
}
.BrainhubCarousel--isRTL .BrainhubCarousel__custom-arrowLeft span {
  transform: rotate(180deg);
}
.BrainhubCarousel--isRTL .BrainhubCarousel__arrowRight span {
  transform: translate(-50%, -50%) rotate(-135deg);
  margin-left: 2.45px;
}
.BrainhubCarousel--isRTL .BrainhubCarousel__custom-arrowRight span {
  transform: rotate(-180deg);
}
.BrainhubCarousel--isRTL .BrainhubCarousel__arrows:hover span {
  margin: 0;
}
.BrainhubCarousel__arrow--disable {
  pointer-events: none;
}

/*# sourceMappingURL=style.css.map*/

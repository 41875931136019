// Navbar
$navbar__height: 106px;
$navbar_small__offset: 51px;
$navbar_small__height: $navbar__height - $navbar_small__offset;
$subnavbar_height: 32px;
$container__max-width: 1164px;
$container-content__max-width: 1100px;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 1164px,
  md: 1196px,
  lg: 1164px,
  max-component-with: 1164px,
  // Used for components that shouldn't be on full width of cointainer.
  xl: 1164px
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 20px !default;

$grid-item-gap: 2px;
$grid-item-gap-desktop: 4px;

$container-mobile__padding: 16px;
$container-tablet__padding: 48px;
$container-desktop__padding: 32px;

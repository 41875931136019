@import "../../../../styles/lib/variables";
@import "../../../../styles/lib/utillities";

.room-type {
  .description {
    margin-top: 32px;
  }

  .room-type-gallery-item {
    width: 300px;
    height: 260px;
    position: relative;
    margin-right: 16px;

    @include overlay-gradient(rgba(0, 0, 0, 0), 45%, rgba(0, 0, 0, 0.8), 100%);

    span {
      position: absolute;
      left: 24px;
      bottom: 24px;
      color: white;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 1;
      font-weight: 700;
    }
  }

  .jtc-component-itinerary-notice {
    margin-top: 32px;
  }
}

@import "../../../styles/lib/variables";

$__dot-size: 6px;

.jtc-component-jtc-improved-slider {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  -ms-touch-action: pan-y;
  touch-action: pan-y;

  &.slider-in-modal {
    padding: 0 !important;
  }

  .carousel-wrapper {
    position: relative;

    &,
    & > div:not(.carousel-footer) {
      height: 100%;
      width: 100%;
    }
  }

  .carousel-footer {
    position: absolute;
    bottom: 24px;
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    .play-pause {
      vertical-align: bottom;
      height: $__dot-size;
      font-size: $__dot-size + 1;
      color: #fff;
      margin-right: 6px;
      text-shadow: 1px 3px 4px rgba(0, 0, 0, 0.4);
      display: block;
    }

    .jtc-improved-slider-thumbnail {
      width: 50px;
      height: 50px;
    }
  }

  .BrainhubCarousel {
    &,
    &__trackContainer,
    &__track,
    &Item {
      height: auto;
      width: 100%;
    }

    &Item > div {
      width: 100%;
    }

    &__track {
      li {
        position: static;
      }
    }

    &__dots {
      display: flex;
      gap: 12px;

      .jtc-improved-slider-dot,
      .BrainhubCarousel__dot {
        margin: 0;
        padding: 0;
        width: $__dot-size;
        height: $__dot-size;
        line-height: $__dot-size;
        background: $shades-medium__color;
        border-radius: 50%;
        transition: background 0.2s;
        display: inline-block;
        opacity: 1;

        &--selected {
          background-color: $theme__color__primary;
          box-shadow: 0 0 0 3px $theme__color__primary;

        }

        &:before {
          display: none;
        }

        &:hover {
          background: $primary__color__lighter;
          box-shadow: 0 0 0 3px $primary__color__lighter;
        }
      }
    }

    &__thumbnail {
      padding-left: 3px;
      padding-right: 3px;

      .tooltip-wrapper {
        visibility: hidden;

        @media (min-width: $screen-md) {
          visibility: visible;
        }
      }

      &--selected {
        .jtc-improved-slider-dot {
          &,
          &:hover {
            border-color: rgba(255, 255, 255, 1);
            background: rgba(255, 255, 255, 1);
          }
        }
      }
    }
  }

  &.arrows-position-top {
    .jtc-improved-slider-arrow {
      align-self: flex-start;
      margin-top: 42px;
    }
  }

  .jtc-improved-slider-arrow {
    position: absolute;
    z-index: 10;

    $__arrow-offset: $indent__s;

    &.arrow-direction-left {
      left: $__arrow-offset;

      @media (min-width: $screen-md) {
        .left-arrow-shape {
          &.default {
            width: 52px;
            height: 52px;
          }
        }
      }
    }

    &.arrow-direction-right {
      right: $__arrow-offset;

      @media (min-width: $screen-md) {
        .right-arrow-shape {
          &.default {
            width: 52px;
            height: 52px;
          }
        }
      }
    }
  }

  &:not(.arrows-variant-floating) {
    .carousel-wrapper {
      padding-left: 32px;
      padding-right: 32px;
    }

    .jtc-improved-slider-arrow {
      &.arrow-direction-left {
        left: 0;
      }

      &.arrow-direction-right {
        right: 0;
      }
    }
  }

  &.no-arrows {
    .carousel-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.with-thumbnails {
    .carousel-footer {
      position: static;
      width: 100%;
      overflow: auto;
    }

    &.arrows-position-center {
      .jtc-improved-slider-arrow {
        margin-top: -35px;
      }
    }
  }
}

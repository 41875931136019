@import "../../../styles/lib/variables";


.jtc-component-payment-form-logogs {
  margin-top: 24px;

  .jtc-component-payment-form-logo {
    &.small {
      height: 15px;
      margin-right: $indent__s;
    }
  }
}


@import "../../../styles/lib/variables";

.jtc-component-share-buttons {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($color-white, 0.2);
    width: 52px;
    height: 52px;
    backdrop-filter: blur(50px);

    clip-path: $irregularCirlcle_polygon;

    @media (min-width: $screen-xs) {
      width: 72px;
      height: 72px;
    }

    > span {
      display: flex;
    }
  }
}

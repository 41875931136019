@import "../../../styles/lib/variables";

.jtc-component-jtc-cookie-management {
  position: fixed;
  bottom: 16px;
  background: white;
  max-width: 460px;
  width: calc(100% - 32px);
  left: 16px;
  padding: 20px 20px 0px;
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  z-index: 1300;
  opacity: 0;
  transition: opacity 0.2s;

  &.show {
    opacity: 1;
  }

  h2 {
    margin: 0 0 20px 0;
  }

  .heading {
    margin-bottom: 16px;
  }

  .title {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.02em;
  }

  .subtitle {
    display: block;
    font-size: 13px;
    font-weight: $font-weight__medium;
    line-height: 20px;
    letter-spacing: 0.04em;

    a {
      color: $color-green-light2;
    }
  }

  .content {
    .jtc-component-jtc-checkbox-with-label__label-text {
      font-size: 13px;
    }
  }

  .contentActions {
    margin-top: 16px;
    margin-bottom: 20px;
  }

  .actions {
    display: flex;
    gap: 12px;

    & > * {
      flex: 1 1 100%;
    }

    button {
      width: 100%;
    }
  }

  .settings {
    text-align: center;
  }
}

@media (min-width: $screen-md) and (max-width: $screen-lg) {
  .jtc-component-jtc-cookie-management {
    bottom: 40px;
    left: 40px;
    padding: 24px 24px 4px;
    width: calc(100% - 48px);

    .heading {
      margin-bottom: 20px;
    }

    .content {
      margin-top: 4px;
    }

    .contentActions {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .settings {
      margin-top: 2px;
    }
  }
}

@media (min-width: $screen-lg) {
  .jtc-component-jtc-cookie-management {
    bottom: 60px;
    max-width: 560px;
    left: 60px;
    padding: 28px 28px 8px;
    width: calc(100% - 56px);

    .heading {
      margin-bottom: 24px;
    }

    .title {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02em;
    }

    .content {
      margin-top: 4px;
    }

    .contentActions {
      margin-top: 24px;
      margin-bottom: 20px;
    }

    .settings {
      margin-top: 6px;
    }
  }
}

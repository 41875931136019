@import "../variables";
@import "../utillities/typography";

.abs-button-small {
  @extend .label;
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  border-radius: 50px;
  backdrop-filter: blur(12px);
  white-space: nowrap;
}

.button-small-error {
  @extend .abs-button-small;
  background: rgba(#FF554A, .24);
  color: #FF554A;
}

.button-small-primary {
  @extend .abs-button-small;
  background: $theme__color__primary;
  color: $primary__color;
  box-shadow: 0 0 12px rgba($theme__color__primary, 0.5);
}

.button-small-secondary {
  @extend .abs-button-small;
  background: rgba($shades-dark__color, .24);
  color: $primary__color;
}

.button-small-secondary-light {
  @extend .abs-button-small;
  background: rgba($color-white, .16);
  color: $shades-extra-light__color;
}

.button-small-tertiary {
  @extend .abs-button-small;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-transform: initial;
  color: $shades-extra-light__color;
  background-color: $primary__color;
  border: 2px solid $shades-medium__color;
  padding: 16px 24px;
}

.button-small-tertiary-active {
  @extend .button-small-tertiary;
  background-color: $color-green-light0;
  color: $primary__color
}

.button-small-active {
  @extend .abs-button-small;
  background-color: $sun__color;
  box-shadow: 0 0 12px rgba($sun__color, 0.5);
  color: $primary__color
}

.button-small-active-count {
  @extend .abs-button-small;
  @extend .button-small-active;
  padding: 5px;
}

@import "../../../styles/lib/variables";
@import "../../../styles/lib/utillities/mixins";

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scale-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.jtc-component-stories-renderer-underlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1999999999999;
  background: $modal__background-color;

  &.initial-story {
    animation: scale-in 0.5s;
  }
}

.jtc-component-stories-renderer {
  position: fixed;
  top: 0;
  z-index: 2000000000000;
  width: 100%;
  max-width: 720px;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;

  display: flex;
  flex-direction: column;

  @media (min-width: $screen-md) {
    max-width: calc(100vh * 9 / 16);
  }

  .story-header {
    display: flex;
    align-items: center;

    .profile-image {
      position: relative;
      width: 64px;
      height: 64px;
      padding: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      filter: drop-shadow(0px 0px 12px rgba($theme__color__primary, 0.5));

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        background: linear-gradient(to bottom, #325f61, #47edbc);
        padding: 3px;
        border-radius: 50%;
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }

      > div {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    h5 {
      margin: 0;
      color: $color-white;
    }
  }

  .story-title-overlay {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2000000000010;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    animation: slide-in-right 0.5s, fade-out 1s;
    opacity: 0;
    will-change: transform, opacity;
    pointer-events: none;

    span {
      color: white;
      font-size: 24px;

      @media (min-width: $screen-md) {
        font-size: 48px;
      }
    }
  }

  .close-stories {
    position: absolute;
    right: 24px;
    top: 32px;
    color: rgba(255, 255, 255, 0.8);
    z-index: 2000000000002;
    cursor: pointer;

    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }

  .prev-story-press-overlay,
  .next-story-press-overlay {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    z-index: 2000000000001;
    cursor: pointer;
  }

  .prev-story-press-overlay {
    left: 0;
  }

  .next-story-press-overlay {
    left: 50%;
  }

  .story-item-details {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2000000000005;
    width: 100%;
    will-change: opacity;
    opacity: 1;
    animation: fade-in 0.5s;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: end;
    top: 60%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
    color: $color-white;

    &-inside {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      width: 100%;
    }

    .content-wrapper {
      padding: 32px 16px;
      max-width: 800px;
      margin: 0 auto;
      width: 100%;
      @media (min-width: $screen-md) {
        padding: 24px 32px
      }
    }

    h5 {
      margin-bottom: 8px;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
    }

    .info-bar {
      background-color: $primary__color;
      color: white;
      display: block;
      font-weight: 500;
      width: 100%;

      .content-wrapper {
        padding: 24px 24px;
        text-align: center;
        vertical-align: middle;

        i {
          line-height: 1;
          font-size: 16px;
          margin-right: 9px;
        }
      }

      &.has-link {
        .content-wrapper {
          position: relative;
          text-align: left;

          &:after {
            position: absolute;
            right: 24px;
            top: 50%;
            content: "\f105";
            transform: translateY(-50%);
            font-family: "Font Awesome 5 Pro";
            font-weight: 300;
            color: white;
            font-size: 20px;

            @media (min-width: $screen-md) {
              right: 10px;
            }
          }
        }
      }
    }
  }

  > div:first-child {
    will-change: transform;

    animation: slide-in-right 0.5s;

    > div:nth-child(2) {
      // This is main element of story's content
      justify-content: center;

      > div {
        display: block !important;
        width: 100% !important;
        height: 100% !important;

        > video {
          width: 100% !important;
          height: 100% !important;
          object-fit: cover;
        }

        > img {
          width: 100% !important;
          height: 100% !important;
          object-fit: cover;
          pointer-events: none;
        }
      }
    }

    > div:nth-child(3) {
      // This is wrapper for prev/next overlays
      // !importans are required here because these elements are styled inline :(
      @media (min-width: $screen-md) {
        > div {
          // Both prev and next
          width: calc(50% + 80px) !important;
          position: absolute;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          font-size: 26px;
          cursor: pointer;
          color: $shades-light__color;
          padding: 30px;

          &:after {
            font-family: "Font Awesome 5 Pro";
          }
        }

        > div:first-child {
          // Prev
          left: -80px;
          justify-content: flex-start;
          &:after {
            content: "\f0a8";
          }
        }

        > div:last-child {
          // Next
          left: 50%;
          justify-content: flex-end;
          &:after {
            content: "\f0a9";
          }
        }
      }
    }
  }

  &.image {
    img {
      display: block;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
    }

    > div:first-child {
      > div:nth-child(2) {
        > div:nth-child(1) {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  &.initial-story {
    .story-title-overlay {
      animation: scale-in 0.5s, fade-out 1s;
    }

    > div:first-child {
      animation: scale-in 0.5s;
    }
  }

  &.previous-story {
    .story-title-overlay {
      animation: slide-in-left 0.5s, fade-out 1s;
    }

    > div:first-child {
      animation: slide-in-left 0.5s;
    }
  }

  &.inline {
    position: relative;
    z-index: initial;

    @media (min-width: $screen-md) {
      width: 100%;
      left: 0;
      transform: none;
    }

    > div:first-child {
      animation: none;
    }

    > div {
      > div:nth-child(2) {
        // This is main element of story's content
        justify-content: center;

        > div {
          position: absolute;
          left: 0;
          top: 0;
          width: 100% !important;
          height: 100% !important;

          > video {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover;
          }

          > img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover;
          }
        }
      }
    }
  }

  &.hide-progressbar {
    > div:first-child {
      > div:first-child {
        // Stories loader element

        visibility: hidden;
      }
    }
  }

  .share-story {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: space-between;
    color: white;
    padding: 24px 16px;
    width: 100%;

    @media (min-width: $screen-xs) {
      flex-flow: row nowrap;
      align-items: center;
    }

    .share-story-title {
      margin-bottom: 16px;
      @media (min-width: $screen-xs) {
        margin-bottom: 0;
      }
    }

    .jtc-component-share-buttons {
      a {
        font-size: 20px;
      }
    }
  }
}

@import "../../../styles/lib/variables";
@import "../../../styles/lib/utillities";

body {
  overflow-x: hidden;
}

.jtc-component-scroller-with-arrow {
  @media (min-width: $screen-md) and (max-width: $screen-md-max) {
    .container & {
      &.full-width {
        margin-left: -$container-tablet__padding;
        margin-right: -$container-tablet__padding;
        width: calc(100vw - #{$container-tablet__padding}) !important;
      }
    }
  }

  @media (min-width: $screen-lg) {
    .container & {
      &.full-width {
        margin-left: calc(-1 * (100vw - $container-content__max-width) / 2);
        margin-right: calc(-1 * (100vw - $container-content__max-width) / 2);
        width: 100vw !important;
      }
    }
  }

  &__list {
    display: flex;
    align-items: center;
    gap: 16px;
    overflow: auto;
    scroll-padding-left: 0;
    padding-left: 0;
    padding-right: 0;
    margin-right: -15px !important;
    margin-bottom: -15px !important;
    padding-bottom: 15px;

    @media (min-width: $screen-md) {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar { /* Hide scrollbar for Chrome, Safari and Opera */
        display: none;
      }
    }

    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      scroll-padding-left: $container-tablet__padding;
      padding-left: $container-tablet__padding;
      padding-right: $container-tablet__padding;
    }
    @media (min-width: $screen-lg) {
      scroll-padding-left: calc((100vw - $container-content__max-width) / 2);
      padding-left: calc((100vw - $container-content__max-width) / 2);
      padding-right: calc((100vw - $container-content__max-width) / 2);
    }
  }
}

.scroller-with-arrow-button {
  @include button-reset();
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  display: none;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  background: $color-green-light0;
  clip-path: $irregularCirlcle_polygon;

  &.left {
    left: -26px;

    .container-fluid & {
      left: 26px;
    }
  }

  &.right {
    right: -26px;

    .container-fluid & {
      right: 26px;
    }
  }

  @media (min-width: $screen-lg) {
    display: flex;
  }
}

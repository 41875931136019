.jtc-app.jtc-blur {
  filter: blur(25px);
}
.jtc-app.customerArea {
  background-color: #fff;
}
.jtc-app.with-hidden-footer footer {
  display: none;
}

footer.footer {
  position: relative;
  z-index: 1;
}


@import "../../../../styles/lib/variables";

.jtc-page-layout {
  display: flex;
  flex-flow: column nowrap;

  &.two-columns-left {
    padding: 0;

    .left-sidebar,
    .page-content {
      width: 100%;
    }

    .left-sidebar {
      .jtc-component-cms-components-renderer,
      .jtc-component-cms-component-renderer {
        height: 100%;
      }

      .jtc-component-cms-component-renderer {
        display: block;
      }
    }

    @media (min-width: $screen-md) {
      flex-flow: row nowrap;
      padding: 0 $indent__base;

      .left-sidebar {
        width: 320px;
        min-width: 320px;
        padding-right: $indent__base * 0.5;
      }

      .jtc-page-content {
        flex-grow: 1;
        padding-left: $indent__base * 0.5;
        min-width: 0;
      }
    }
  }
}

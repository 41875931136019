@import "../../../styles/lib/variables";

.right-arrow-wrapper {
  &.glow {
    filter: drop-shadow(0px 0px 12px $color-green-light0);
  }

  .right-arrow-shape {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    background: $color-green-light0;
    clip-path: $irregularCirlcle_polygon;
    cursor: pointer;

    &.small {
      width: 40px;
      height: 40px;
    }

    &.medium {
      width: 52px;
      height: 52px;
    }

    @media (min-width: $screen-md) {
      width: 72px;
      height: 72px;
    }
  }
}

.left-arrow-wrapper {
  &.glow {
    filter: drop-shadow(0px 0px 12px $color-green-light0);
  }

  .left-arrow-shape {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    background: $shades-medium__color;
    clip-path: $irregularCirlcle_polygon;
    cursor: pointer;

    &.small {
      width: 40px;
      height: 40px;
    }

    &.medium {
      width: 52px;
      height: 52px;
    }

    @media (min-width: $screen-md) {
      width: 72px;
      height: 72px;
    }
  }
}

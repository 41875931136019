.tooltip-wrapper-element {
  display: inline-flex;
  position: relative;
}
.tooltip-wrapper-element div[role=tooltip] {
  opacity: 1;
  font-size: 13px;
  min-width: 200px;
  z-index: 100000;
  text-align: center;
  font-weight: normal;
}
.tooltip-wrapper-element div[role=tooltip][x-placement=top] > div {
  box-shadow: 0 -4px 40px 0 rgba(25, 53, 55, 0.1);
}
.tooltip-wrapper-element div[role=tooltip] > div {
  margin: 8px 0;
  font-size: 13px;
  background-color: #fff;
  border: 1px solid #EAE3DC;
  font-weight: 500;
  line-height: 20px;
  padding: 12px;
  box-shadow: 0 4px 40px 0 rgba(25, 53, 55, 0.1);
}
.tooltip-wrapper-element div[role=tooltip] > div,
.tooltip-wrapper-element div[role=tooltip] > div a {
  color: #151515;
}
.tooltip-wrapper-element div[role=tooltip] > div a {
  text-decoration: underline;
}

.jtc-component-jtc-tooltip .tooltip-icon svg {
  display: block;
}


@import "../../styles/lib/variables";

.jtc-app {
  &.jtc-blur {
    filter: $modal__blur;
  }

  &.customerArea {
    background-color: $color-white;
  }

  &.with-hidden-footer {
    footer {
      display: none;
    }
  }
}

footer.footer{
  position: relative;
  z-index: 1;

}

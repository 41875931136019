@import "../../../styles/lib/variables";

.jtc-component-cms-panel {
  position: fixed;
  bottom: 0px;
  left: 0px;
  padding: 10px;
  padding-right: 0;
  background: $white;
  z-index: 100;

  a {
    color: $color-green-light0;
    margin-right: 10px;
  }
}

@import "../../../styles/lib/variables";

.jtc-checkbox {
  &.disabled {
    opacity: 0.38;
  }

  &.check-icon{
    .jtc-checkbox__default{
      border: 0;
    }
    .jtc-checkbox__checked{
      background-color: transparent;
      color: $color-green-light2;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      i{
        width: auto;
        height: auto;
        display: inline;
        background: transparent;
        border-radius: 3px;
        font-size: 18px;
        font-weight: 500;
        &:before{
          display: block;
        }
      }
    }
  }

  &__default {
    width: 24px;
    height: 24px;
    background: rgba($shades-dark__color, 16%);
    border-radius: 3px;
  }

  &__checked {
    width: 24px;
    height: 24px;
    font-size: 12px;
    border-radius: 3px;
    background: rgba($shades-dark__color, 16%);
    border: 2px solid transparent;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      position: relative;
      width: 12px;
      height: 12px;
      display: block;
      background: $sun__color;
      border-radius: 3px;
      box-shadow: 0 0 12px 0 rgba($sun__color, 50%);
      // Styles here are important to avoid unexpected overriding
      margin: 0 !important;
      vertical-align: middle !important;

      &:before{
        display: none;
      }
    }
  }
}

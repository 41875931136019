@import "../../../../../styles/lib/variables";

.mobile-fullscreen-modal-component-content-mobile {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 50000;
  background: $color-white;
  will-change: transform;

  transform: translateY(100%);
  transition: transform 0.2s;

  &.open {
    transform: translateY(0);
  }

  .modal-wrapper {
    overflow: auto;
    height: 100vh;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    position: relative;
    z-index: 10;

    .close-button {
      font-size: 20px;
      cursor: pointer;
      color: $primary__color;
      position: absolute;
      top: 24px;
      right: 24px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .modal-navigation {
    padding: 0 16px 24px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    margin-top: auto;

    span {
      display: flex;
      align-items: center;
    }

    .prev,
    .next {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .next {
      margin-left: auto;
    }
  }

  .BrainhubCarouselItem {
    align-items: flex-start;
  }

  .mobile-fullscreen-modal-content {
    padding: 16px;
  }

  .score-inside {
    margin-top: 14px;

    .jtc-component-cms-components-renderer {
      display: grid;
      grid-template-columns: 1fr 8fr;
      grid-template-rows: auto 1fr;
      row-gap: 24px;

      > div:nth-child(1) {
        grid-column: 1;
        grid-row: 1;
      }

      > div:nth-child(2) {
        grid-column: 2;
        grid-row: 1;
        align-items: center;
        margin-bottom: 20px;

        padding-left: 20px;
        padding-right: 48px;

        * {
          margin: 0;
        }
      }

      > div {
        grid-column: 1/3;
      }
    }
  }

  .carousel-footer {
    bottom: 24px;

    @media (max-width: $screen-md) {
      background: $color-white;
      padding: 16px;
      bottom: 0;
    }
  }
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

p {
  letter-spacing: 0.3px;
}

h1 {
  font-family: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 48px;
  line-height: 48px;
}
@media (min-width: 992px) {
  h1 {
    font-size: 60px;
    line-height: 60px;
  }
}
@media (min-width: 1200px) {
  h1 {
    font-size: 80px;
    line-height: 76px;
  }
}

h2 {
  font-family: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 40px;
  line-height: 40px;
}
@media (min-width: 992px) {
  h2 {
    font-size: 48px;
    line-height: 48px;
  }
}
@media (min-width: 1200px) {
  h2 {
    font-size: 60px;
    line-height: 56px;
  }
}

h3 {
  font-family: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.56px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 28px;
}
@media (min-width: 992px) {
  h3 {
    font-size: 32px;
    line-height: 32px;
  }
}
@media (min-width: 1200px) {
  h3 {
    font-size: 36px;
    line-height: 36px;
  }
}

h4 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 0.18px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
}
@media (min-width: 992px) {
  h4 {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (min-width: 1200px) {
  h4 {
    font-size: 24px;
    line-height: 28px;
  }
}

h5 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.16px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 20px;
}
@media (min-width: 992px) {
  h5 {
    font-size: 18px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  h5 {
    font-size: 20px;
    line-height: 24px;
  }
}

h6 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.28px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 992px) {
  h6 {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  h6 {
    font-size: 16px;
    line-height: 24px;
  }
}

.h6 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.28px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 992px) {
  .h6 {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  .h6 {
    font-size: 16px;
    line-height: 24px;
  }
}

.label {
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

.jtc-component-jtc-gallery-component .BrainhubCarouselItem {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-self: center;
}
.jtc-component-jtc-gallery-component .BrainhubCarouselItem img {
  display: block;
  max-height: calc(100vh - 350px);
  max-width: 99%;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
  margin: 0 auto;
}
.jtc-component-jtc-gallery-component .BrainhubCarouselItem .user-profile-gallery-item {
  display: flex;
  flex-flow: column nowrap;
  height: calc(100vh - 140px);
}
.jtc-component-jtc-gallery-component .BrainhubCarouselItem .user-profile-gallery-item .image-wrapper {
  flex-grow: 1;
  width: 100%;
}
.jtc-component-jtc-gallery-component .BrainhubCarouselItem .user-profile-gallery-item .item-description {
  align-self: flex-start;
}
.jtc-component-jtc-gallery-component .BrainhubCarouselItem .user-profile-gallery-item .item-description .user-name,
.jtc-component-jtc-gallery-component .BrainhubCarouselItem .user-profile-gallery-item .item-description .user-basic-info,
.jtc-component-jtc-gallery-component .BrainhubCarouselItem .user-profile-gallery-item .item-description .user-description {
  font-weight: 300;
}
.jtc-component-jtc-gallery-component .BrainhubCarouselItem .user-profile-gallery-item .item-description .user-basic-info {
  opacity: 0.8;
  margin-bottom: 8.5px;
}
.jtc-component-jtc-gallery-component .BrainhubCarouselItem .user-profile-gallery-item .item-description .user-name {
  font-size: 36px;
}
.jtc-component-jtc-gallery-component .BrainhubCarouselItem .user-profile-gallery-item .item-description .user-name .is-skipper-text {
  font-size: 27px;
}
.jtc-component-jtc-gallery-component .BrainhubCarouselItem .user-profile-gallery-item .item-description .user-basic-info,
.jtc-component-jtc-gallery-component .BrainhubCarouselItem .user-profile-gallery-item .item-description .user-description {
  font-size: 18px;
}
@media (min-width: 992px) {
  .jtc-component-jtc-gallery-component .BrainhubCarouselItem .user-profile-gallery-item .item-description .user-name {
    font-size: 53px;
  }
  .jtc-component-jtc-gallery-component .BrainhubCarouselItem .user-profile-gallery-item .item-description .user-name .is-skipper-text {
    font-size: 36px;
    opacity: 0.8;
  }
  .jtc-component-jtc-gallery-component .BrainhubCarouselItem .user-profile-gallery-item .item-description .user-basic-info,
  .jtc-component-jtc-gallery-component .BrainhubCarouselItem .user-profile-gallery-item .item-description .user-description {
    font-size: 25px;
  }
}
.jtc-component-jtc-gallery-component .BrainhubCarouselItem .user-profile-gallery-item .item-description .user-description {
  overflow: auto;
  max-height: 75px;
}
@media (min-width: 992px) {
  .jtc-component-jtc-gallery-component .BrainhubCarouselItem .user-profile-gallery-item .item-description .user-description {
    max-height: 90px;
  }
}
.jtc-component-jtc-gallery-component .BrainhubCarouselItem .instagram-info {
  margin-bottom: 10px;
}
.jtc-component-jtc-gallery-component .BrainhubCarouselItem .instagram-info .instagram {
  color: #fff;
}
.jtc-component-jtc-gallery-component .jtc-improved-slider-arrow {
  color: white;
}
.jtc-component-jtc-gallery-component button {
  visibility: hidden;
  pointer-events: none;
}
@media (min-width: 992px) {
  .jtc-component-jtc-gallery-component button {
    visibility: visible;
    pointer-events: initial;
  }
}


.mobile-fullscreen-modal-component-content-desktop .mobile-fullscreen-modal-component-content-desktop-dialog-paper {
  max-height: calc(100% - 48px);
  margin: 24px;
}
.mobile-fullscreen-modal-component-content-desktop > div:last-child {
  width: 100%;
}
.mobile-fullscreen-modal-component-content-desktop .modal-wrapper {
  display: flex;
  outline: none;
  position: relative;
  overflow-y: auto;
  flex-direction: column;
  height: 100%;
}
.mobile-fullscreen-modal-component-content-desktop .mobile-fullscreen-modal-content {
  padding-top: 8.5px;
}
.mobile-fullscreen-modal-component-content-desktop .header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.mobile-fullscreen-modal-component-content-desktop .header .close-button {
  font-size: 20px;
  cursor: pointer;
  color: #193537;
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-fullscreen-modal-component-content-desktop .modal-navigation {
  padding: 16px 24px 56px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  margin-top: auto;
}
.mobile-fullscreen-modal-component-content-desktop .modal-navigation span {
  display: flex;
  align-items: center;
}
.mobile-fullscreen-modal-component-content-desktop .modal-navigation .prev,
.mobile-fullscreen-modal-component-content-desktop .modal-navigation .next {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.mobile-fullscreen-modal-component-content-desktop .modal-navigation .next {
  margin-left: auto;
}
.mobile-fullscreen-modal-component-content-desktop .score-inside {
  margin-top: 14px;
}
.mobile-fullscreen-modal-component-content-desktop .score-inside .jtc-component-cms-components-renderer {
  display: grid;
  grid-template-columns: 1fr 8fr;
  grid-template-rows: auto 1fr;
  row-gap: 24px;
}
.mobile-fullscreen-modal-component-content-desktop .score-inside .jtc-component-cms-components-renderer > div:nth-child(1) {
  grid-column: 1;
  grid-row: 1;
}
.mobile-fullscreen-modal-component-content-desktop .score-inside .jtc-component-cms-components-renderer > div:nth-child(2) {
  grid-column: 2;
  grid-row: 1;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 48px;
}
.mobile-fullscreen-modal-component-content-desktop .score-inside .jtc-component-cms-components-renderer > div:nth-child(2) * {
  margin: 0;
}
.mobile-fullscreen-modal-component-content-desktop .score-inside .jtc-component-cms-components-renderer > div {
  grid-column: 1/3;
}


.jtc-component-footer-partners-component {
  overflow-x: hidden;
  padding: 32px 32px 32px;
  position: relative;
  z-index: 1;
}
.jtc-component-footer-partners-component:after {
  clip-path: polygon(75% 0.2%, 0% 5%, 0% 100%, 74% 97%, 100% 100%, 100% 5%, 75% 0.2%);
  background: #47EDBC;
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
@media (min-width: 992px) {
  .jtc-component-footer-partners-component:after {
    clip-path: polygon(78% 0.2%, 0% 8%, 0% 100%, 78% 92%, 100% 99%, 100% 8%, 78% 0.2%);
  }
}
@media (min-width: 992px) {
  .jtc-component-footer-partners-component {
    overflow: initial;
    padding: 80px 32px 80px;
  }
}
.jtc-component-footer-partners-component .partners-logos {
  justify-content: center;
}
.jtc-component-footer-partners-component .partners-logos .partners-logos__list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  list-style: none;
  max-width: 720px;
}
@media (min-width: 992px) {
  .jtc-component-footer-partners-component .partners-logos .partners-logos__list {
    flex-wrap: nowrap;
  }
}
.jtc-component-footer-partners-component .partners-logos .partners-logos__list .partner-box {
  display: flex;
  flex-basis: calc(33.333% - 17px);
  min-width: fit-content;
  text-align: center;
  margin: 8.5px;
  padding: 0;
  border: none;
  box-shadow: none;
}
.jtc-component-footer-partners-component .partners-logos .partners-logos__list .partner-box:hover {
  background: none;
}
.jtc-component-footer-partners-component .partners-logos .partners-logos__list .partner-box:hover .tooltip > div {
  display: block;
  font-size: 15px;
}
.jtc-component-footer-partners-component .partners-logos .partners-logos__list .partner-box > div {
  margin: 0 auto;
  background: transparent;
}
.jtc-component-footer-partners-component .partners-logos .partners-logos__list .partner-box .partner-link {
  display: block;
}
.jtc-component-footer-partners-component .partners-logos .partners-logos__list .partner-box img {
  display: block;
  height: 34px;
  margin: 0 auto;
}
.jtc-component-footer-partners-component .partners-logos .partners-logos__list .partner-box .tooltip {
  pointer-events: all;
  width: auto;
  height: auto;
  box-shadow: none;
  border-radius: 0;
}
.jtc-component-footer-partners-component .partners-logos .partners-logos__list .partner-box .tooltip > div {
  display: none;
}
.jtc-component-footer-partners-component .partners-logos .partners-logos__list .partner-box.hh {
  order: 2;
}
.jtc-component-footer-partners-component .partners-logos .partners-logos__list .partner-box.hh img {
  min-width: 55px;
}
.jtc-component-footer-partners-component .partners-logos .partners-logos__list .partner-box.dream-yacht-charter {
  order: 3;
}
.jtc-component-footer-partners-component .partners-logos .partners-logos__list .partner-box.dream-yacht-charter img {
  min-width: 80px;
}
.jtc-component-footer-partners-component .partners-logos .partners-logos__list .partner-box.yacht-pool {
  order: 4;
}
.jtc-component-footer-partners-component .partners-logos .partners-logos__list .partner-box.yacht-pool img {
  min-width: 80px;
}
.jtc-component-footer-partners-component .partners-logos .partners-logos__list .partner-box.ultra {
  order: 5;
}
.jtc-component-footer-partners-component .partners-logos .partners-logos__list .partner-box.ultra img {
  min-width: 160px;
}
@media (min-width: 992px) {
  .jtc-component-footer-partners-component .partners-logos .partners-logos__list .partner-box {
    flex: auto;
  }
}


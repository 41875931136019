@import "../../../styles/lib/variables";
@import "../../../styles/lib/utillities";

.jtc-component-form-mode-selector {
  @include switcher(100%, row, label);
  margin: 16px auto;
  width: 100%;

  .switcher-item-wrapper {

    .tooltip-wrapper-element {
      display: block;
    }
  }

  &.has-one-option {
    .switcher-item-wrapper {
      width: 100%;
      max-width: 100%;
    }
  }
}

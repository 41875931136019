@import "../../../../../styles/lib/variables";

.mobile-fullscreen-modal-component-content-desktop {
  .mobile-fullscreen-modal-component-content-desktop-dialog-paper {
    max-height: calc(100% - 48px);
    margin: 24px;
  }

  > div:last-child {
    width: 100%;
  }

  .modal-wrapper {
    display: flex;
    outline: none;
    position: relative;
    overflow-y: auto;
    flex-direction: column;
    height: 100%;
  }

  .mobile-fullscreen-modal-content {
    padding-top: $indent__s;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .close-button {
      font-size: 20px;
      cursor: pointer;
      color: $primary__color;
      position: absolute;
      top: 24px;
      right: 24px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .modal-navigation {
    padding: 16px 24px 56px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    margin-top: auto;

    span {
      display: flex;
      align-items: center;
    }

    .prev,
    .next {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .next {
      margin-left: auto;
    }
  }

  .score-inside {
    margin-top: 14px;

    .jtc-component-cms-components-renderer {
      display: grid;
      grid-template-columns: 1fr 8fr;
      grid-template-rows: auto 1fr;
      row-gap: 24px;

      > div:nth-child(1) {
        grid-column: 1;
        grid-row: 1;
      }

      > div:nth-child(2) {
        grid-column: 2;
        grid-row: 1;
        align-items: center;
        margin-bottom: 20px;

        padding-left: 20px;
        padding-right: 48px;

        * {
          margin: 0;
        }
      }

      > div {
        grid-column: 1/3;
      }
    }
  }
}

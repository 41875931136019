.jtc-input-label {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  letter-spacing: 0.96px;

  .tooltip-wrapper-element {
    margin-left: 5px;
  }
}

@import "../../../styles/lib/variables";
@import "../../../styles/lib/utillities";

.yacht-standard-modal {
  [role="document"] {
    max-width: 896px;
  }

  .yacht-standard-modal-title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 0;

    h2 {
      @extend h2;
      margin-bottom: 0;
    }
  }

  .BrainhubCarousel {
    margin-right: -24px;
    width: calc(100% + 24px);
  }

  .jtc-component-jtc-improved-slider {
    .carousel-footer {
      display: none;
    }
  }

  @media (min-width: $screen-md) {
    .BrainhubCarousel {
      margin-right: -40px;
      width: calc(100% + 40px);
    }
  }

  .right-arrow-wrapper {
    filter: none;

    .right-arrow-shape {
      background-color: #a6b7ba;
      width: 72px;
      height: 72px;

      svg {
        path {
          stroke: white;
        }
      }
    }
  }

  .yacht-standard-gallery-item {
    width: 300px;
    height: 260px;
    position: relative;
    margin-right: 16px;

    @include overlay-gradient(rgba(0, 0, 0, 0), 45%, rgba(0, 0, 0, 0.8), 100%);

    span {
      position: absolute;
      left: 24px;
      bottom: 24px;
      color: white;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 1;
      font-weight: 700;
    }
  }

  .gallery-description {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    color: $shades-dark__color;
  }

  .yacht-standard-features {
    margin-top: 32px;
    margin-bottom: 32px;

    &__item {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: center;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .icon {
        width: 24px;
        height: 24px;
      }

      .description {
        flex-grow: 1;
        margin-left: 12px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
      }
    }
  }

  .jtc-component-itinerary-notice {
    margin-top: 8px;
    margin-bottom: 32px;
  }
}

@import "../../../../../styles/lib/variables";

.jtc-language-switcher-dialog {
  @media (max-width: $screen-xs-max) {
    div[role="document"] {
      position: absolute;
      margin: 0;
      left: 0;
      right: 0;
      bottom: 0;
      max-width: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .close-button {
    cursor: pointer;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    justify-content: space-between;
    width: 100%;

    .title {
      margin-bottom: 0;
    }
  }

  .actions {
    margin-bottom: 32px;
  }

  .tag-as-button {
    cursor: pointer;
    border: 2px solid $shades-medium__color;
    border-radius: 50px;
    padding: 6px 16px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    margin-right: 4px;

    &.active {
      background-color: $color-green-light0;
      border: 2px solid $color-green-light0;
    }
  }

  p {
    margin-bottom: 0;
  }
}

@use "sass:math" as math;

@import "../utillities/mixins";

.ais-Hits,
.ais-InfiniteHits {
  width: 100%;

  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
  }

  .load-more-button {
    margin-bottom: $indent__base * 0.5;
    width: 100%;

    @media (min-width: $screen-sm) {
      margin-bottom: $indent__base;
    }
  }

  &.grid-view {
    ul {
      display: flex;
      flex-flow: row wrap;

      li {
        margin-bottom: $indent__base * 0.5;
        position: relative;

        width: calc(50% - #{$indent__base * 1 * 0.25});

        @include aspect-ratio(1, 1);

        @media (max-width: $screen-xs-max) {
          margin-right: $indent__base * 0.5;
          &:nth-child(2n) {
            margin-right: 0;
          }
        }

        @media (min-width: $screen-sm) {
          width: calc(100% / 3 - #{math.div($indent__base, 3)});
        }

        @media (min-width: $screen-sm) and (max-width: $screen-md-max) {
          margin-right: $indent__base * 0.5;
          &:nth-child(3n) {
            margin-right: 0;
          }
        }

        @media (min-width: $screen-lg) {
          width: calc(25% - #{$indent__base * 3 * 0.25});
          margin-right: $indent__base;
          margin-bottom: $indent__base;

          &:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.ais-InstantSearch__root {
  .row > div {
    padding-left: 0;
    padding-right: 0;

    @media (min-width: $screen-sm) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

@import "../../../styles/lib/variables";
@import "../../../styles/lib/utillities";

.jtc-dialog-yacht-modal {
  [role="document"] {
    width: 100%;
    max-width: 896px;
  }

  &__button {
    margin-top: 32px;
  }

  &__gallery-description {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    color: $shades-dark__color;
  }

  .jtc-dialog-title {
    &__content {
      display: flex;
      align-items: center;
      gap: 4px;

      .tooltip-icon-wrapper {
        opacity: 0.5;
      }
    }
  }
}

.jtc-datepicker-button-wrapper {
  @include ie-media-support() {
    height: 34px;
    width: 34px;
    align-items: none;
  }
  button{
    right: -12px;
  }
}

@import "../../../styles/lib/variables";

.trip-feature-in-circle {
  $gap: 3px;
  $scores-rotate-list: 0deg 72deg 144deg -144deg -72deg;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100px !important;
  height: 100px;
  border-radius: 50%;
  position: relative;

  @media (min-width: $screen-md) {
    width: 120px !important;
    height: 120px;
  }

  &.has-modal {
    cursor: pointer;
  }

  .scores {
    .score {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 4px solid rgba(25, 53, 55, 0.2);
      border-radius: 50%;
      clip-path: polygon(
                      calc(50% + #{$gap}) 50%,
                      calc(50% + #{$gap}) 0%,
                      100% 0%,
                      100% calc(33.745% - #{$gap}),
                      50% calc(50% - #{$gap})
      );
      opacity: 1;
    }

    @each $current-rotate in $scores-rotate-list {
      $i: index($scores-rotate-list, $current-rotate);
      .score:nth-child(#{$i}) {
        transform: rotate($current-rotate);
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 16px;

    .icon {
      margin-bottom: 12px;
      height: 20px;

      &.has-modal {
        margin-top: -10px;
      }
    }

    .title {
      font-weight: $font-weight__bold;
      color: $primary__color;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      margin-bottom: 6px;

      @media (min-width: $screen-md) {
        font-size: 12px;
      }
    }

    .info {
      background: $color-white;
      border-radius: 50%;
      font-size: 12px;
      position: absolute;
      bottom: 0;
      width: 25px;
      height: 25px;
      box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;

      @media (min-width: $screen-md) {
        width: 32px;
        height: 32px;
      }
    }
  }
}

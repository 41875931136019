@keyframes pulse {
  0% {
    opacity: 0.25;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.25;
  }
}

.jtc-checkbox.disabled {
  opacity: 0.38;
}
.jtc-checkbox.check-icon .jtc-checkbox__default {
  border: 0;
}
.jtc-checkbox.check-icon .jtc-checkbox__checked {
  background-color: transparent;
  color: #34CFA1;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jtc-checkbox.check-icon .jtc-checkbox__checked i {
  width: auto;
  height: auto;
  display: inline;
  background: transparent;
  border-radius: 3px;
  font-size: 18px;
  font-weight: 500;
}
.jtc-checkbox.check-icon .jtc-checkbox__checked i:before {
  display: block;
}
.jtc-checkbox__default {
  width: 24px;
  height: 24px;
  background: rgba(169, 161, 154, 0.16);
  border-radius: 3px;
}
.jtc-checkbox__checked {
  width: 24px;
  height: 24px;
  font-size: 12px;
  border-radius: 3px;
  background: rgba(169, 161, 154, 0.16);
  border: 2px solid transparent;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jtc-checkbox__checked i {
  position: relative;
  width: 12px;
  height: 12px;
  display: block;
  background: #FFD12F;
  border-radius: 3px;
  box-shadow: 0 0 12px 0 rgba(255, 209, 47, 0.5);
  margin: 0 !important;
  vertical-align: middle !important;
}
.jtc-checkbox__checked i:before {
  display: none;
}


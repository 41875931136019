.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

p {
  letter-spacing: 0.3px;
}

h1 {
  font-family: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 48px;
  line-height: 48px;
}
@media (min-width: 992px) {
  h1 {
    font-size: 60px;
    line-height: 60px;
  }
}
@media (min-width: 1200px) {
  h1 {
    font-size: 80px;
    line-height: 76px;
  }
}

h2 {
  font-family: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 40px;
  line-height: 40px;
}
@media (min-width: 992px) {
  h2 {
    font-size: 48px;
    line-height: 48px;
  }
}
@media (min-width: 1200px) {
  h2 {
    font-size: 60px;
    line-height: 56px;
  }
}

h3 {
  font-family: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.56px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 28px;
}
@media (min-width: 992px) {
  h3 {
    font-size: 32px;
    line-height: 32px;
  }
}
@media (min-width: 1200px) {
  h3 {
    font-size: 36px;
    line-height: 36px;
  }
}

h4 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 0.18px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
}
@media (min-width: 992px) {
  h4 {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (min-width: 1200px) {
  h4 {
    font-size: 24px;
    line-height: 28px;
  }
}

h5 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.16px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 20px;
}
@media (min-width: 992px) {
  h5 {
    font-size: 18px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  h5 {
    font-size: 20px;
    line-height: 24px;
  }
}

h6 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.28px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 992px) {
  h6 {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  h6 {
    font-size: 16px;
    line-height: 24px;
  }
}

.h6 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.28px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 992px) {
  .h6 {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  .h6 {
    font-size: 16px;
    line-height: 24px;
  }
}

.label {
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes scale-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes slide-in-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
.jtc-component-stories-renderer-underlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1999999999999;
  background: rgba(25, 53, 55, 0.85);
}
.jtc-component-stories-renderer-underlay.initial-story {
  animation: scale-in 0.5s;
}

.jtc-component-stories-renderer {
  position: fixed;
  top: 0;
  z-index: 2000000000000;
  width: 100%;
  max-width: 720px;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  .jtc-component-stories-renderer {
    max-width: 56.25vh;
  }
}
.jtc-component-stories-renderer .story-header {
  display: flex;
  align-items: center;
}
.jtc-component-stories-renderer .story-header .profile-image {
  position: relative;
  width: 64px;
  height: 64px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  filter: drop-shadow(0px 0px 12px rgba(71, 237, 188, 0.5));
}
.jtc-component-stories-renderer .story-header .profile-image:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  background: linear-gradient(to bottom, #325f61, #47edbc);
  padding: 3px;
  border-radius: 50%;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.jtc-component-stories-renderer .story-header .profile-image > div {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.jtc-component-stories-renderer .story-header h5 {
  margin: 0;
  color: #fff;
}
.jtc-component-stories-renderer .story-title-overlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2000000000010;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  animation: slide-in-right 0.5s, fade-out 1s;
  opacity: 0;
  will-change: transform, opacity;
  pointer-events: none;
}
.jtc-component-stories-renderer .story-title-overlay span {
  color: white;
  font-size: 24px;
}
@media (min-width: 992px) {
  .jtc-component-stories-renderer .story-title-overlay span {
    font-size: 48px;
  }
}
.jtc-component-stories-renderer .close-stories {
  position: absolute;
  right: 24px;
  top: 32px;
  color: rgba(255, 255, 255, 0.8);
  z-index: 2000000000002;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}
.jtc-component-stories-renderer .prev-story-press-overlay,
.jtc-component-stories-renderer .next-story-press-overlay {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  z-index: 2000000000001;
  cursor: pointer;
}
.jtc-component-stories-renderer .prev-story-press-overlay {
  left: 0;
}
.jtc-component-stories-renderer .next-story-press-overlay {
  left: 50%;
}
.jtc-component-stories-renderer .story-item-details {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2000000000005;
  width: 100%;
  will-change: opacity;
  opacity: 1;
  animation: fade-in 0.5s;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: end;
  top: 60%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  color: #fff;
}
.jtc-component-stories-renderer .story-item-details-inside {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
}
.jtc-component-stories-renderer .story-item-details .content-wrapper {
  padding: 32px 16px;
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}
@media (min-width: 992px) {
  .jtc-component-stories-renderer .story-item-details .content-wrapper {
    padding: 24px 32px;
  }
}
.jtc-component-stories-renderer .story-item-details h5 {
  margin-bottom: 8px;
}
.jtc-component-stories-renderer .story-item-details p {
  margin-top: 0;
  margin-bottom: 0;
}
.jtc-component-stories-renderer .story-item-details .info-bar {
  background-color: #193537;
  color: white;
  display: block;
  font-weight: 500;
  width: 100%;
}
.jtc-component-stories-renderer .story-item-details .info-bar .content-wrapper {
  padding: 24px 24px;
  text-align: center;
  vertical-align: middle;
}
.jtc-component-stories-renderer .story-item-details .info-bar .content-wrapper i {
  line-height: 1;
  font-size: 16px;
  margin-right: 9px;
}
.jtc-component-stories-renderer .story-item-details .info-bar.has-link .content-wrapper {
  position: relative;
  text-align: left;
}
.jtc-component-stories-renderer .story-item-details .info-bar.has-link .content-wrapper:after {
  position: absolute;
  right: 24px;
  top: 50%;
  content: "\f105";
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  color: white;
  font-size: 20px;
}
@media (min-width: 992px) {
  .jtc-component-stories-renderer .story-item-details .info-bar.has-link .content-wrapper:after {
    right: 10px;
  }
}
.jtc-component-stories-renderer > div:first-child {
  will-change: transform;
  animation: slide-in-right 0.5s;
}
.jtc-component-stories-renderer > div:first-child > div:nth-child(2) {
  justify-content: center;
}
.jtc-component-stories-renderer > div:first-child > div:nth-child(2) > div {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
}
.jtc-component-stories-renderer > div:first-child > div:nth-child(2) > div > video {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}
.jtc-component-stories-renderer > div:first-child > div:nth-child(2) > div > img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  pointer-events: none;
}
@media (min-width: 992px) {
  .jtc-component-stories-renderer > div:first-child > div:nth-child(3) > div {
    width: calc(50% + 80px) !important;
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    font-size: 26px;
    cursor: pointer;
    color: #F3EEE8;
    padding: 30px;
  }
  .jtc-component-stories-renderer > div:first-child > div:nth-child(3) > div:after {
    font-family: "Font Awesome 5 Pro";
  }
  .jtc-component-stories-renderer > div:first-child > div:nth-child(3) > div:first-child {
    left: -80px;
    justify-content: flex-start;
  }
  .jtc-component-stories-renderer > div:first-child > div:nth-child(3) > div:first-child:after {
    content: "\f0a8";
  }
  .jtc-component-stories-renderer > div:first-child > div:nth-child(3) > div:last-child {
    left: 50%;
    justify-content: flex-end;
  }
  .jtc-component-stories-renderer > div:first-child > div:nth-child(3) > div:last-child:after {
    content: "\f0a9";
  }
}
.jtc-component-stories-renderer.image img {
  display: block;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}
.jtc-component-stories-renderer.image > div:first-child > div:nth-child(2) > div:nth-child(1) {
  height: 100%;
  width: 100%;
}
.jtc-component-stories-renderer.initial-story .story-title-overlay {
  animation: scale-in 0.5s, fade-out 1s;
}
.jtc-component-stories-renderer.initial-story > div:first-child {
  animation: scale-in 0.5s;
}
.jtc-component-stories-renderer.previous-story .story-title-overlay {
  animation: slide-in-left 0.5s, fade-out 1s;
}
.jtc-component-stories-renderer.previous-story > div:first-child {
  animation: slide-in-left 0.5s;
}
.jtc-component-stories-renderer.inline {
  position: relative;
  z-index: initial;
}
@media (min-width: 992px) {
  .jtc-component-stories-renderer.inline {
    width: 100%;
    left: 0;
    transform: none;
  }
}
.jtc-component-stories-renderer.inline > div:first-child {
  animation: none;
}
.jtc-component-stories-renderer.inline > div > div:nth-child(2) {
  justify-content: center;
}
.jtc-component-stories-renderer.inline > div > div:nth-child(2) > div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
}
.jtc-component-stories-renderer.inline > div > div:nth-child(2) > div > video {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}
.jtc-component-stories-renderer.inline > div > div:nth-child(2) > div > img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}
.jtc-component-stories-renderer.hide-progressbar > div:first-child > div:first-child {
  visibility: hidden;
}
.jtc-component-stories-renderer .share-story {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: space-between;
  color: white;
  padding: 24px 16px;
  width: 100%;
}
@media (min-width: 576px) {
  .jtc-component-stories-renderer .share-story {
    flex-flow: row nowrap;
    align-items: center;
  }
}
.jtc-component-stories-renderer .share-story .share-story-title {
  margin-bottom: 16px;
}
@media (min-width: 576px) {
  .jtc-component-stories-renderer .share-story .share-story-title {
    margin-bottom: 0;
  }
}
.jtc-component-stories-renderer .share-story .jtc-component-share-buttons a {
  font-size: 20px;
}


@use "sass:math" as math;

@import "../../../styles/lib/variables";
@import "../../../styles/lib/utillities/mixins";

.jtc-component-jtc-gallery-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: $modal__background-color;
  -webkit-transition: 0.5s;
  transition: all 0.3s linear;
  overflow: hidden;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  .modal-content {
    color: $color-white;
    width: 100%;
    margin: auto;
    padding: $indent__base;

    .modal-close {
      position: absolute;
      top: $indent__base;
      right: $indent__base;
      height: $modal__close-height;
      width: $modal__close-height;
      font-size: math.div($modal__close-height, 1.5);
      color: $modal__close-color;
      background-color: $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      z-index: 10;
      cursor: pointer;
    }
  }
}

.jtc-page-cms .loading {
  height: 90vh;
  align-items: center;
  justify-content: center;
}

.jtc-component-cms-page-wrapper {
  padding-top: 32px;
}
.with-hidden-submenu .jtc-component-cms-page-wrapper, .with-transparent-navbar .jtc-component-cms-page-wrapper, .with-color-scheme .jtc-component-cms-page-wrapper {
  padding: 0;
}
.with-color-scheme.green .jtc-component-cms-page-wrapper {
  background-color: #193537;
  padding-bottom: 80px;
  margin-bottom: -40px;
}


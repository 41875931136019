@import "../../styles/lib/variables";

.show-gallery-button {
  position: absolute;
  display: none;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: $white;
  opacity: 0.6;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  border: none;
  font-size: 18px;
  line-height: 1;
  padding: 0;
  z-index: 1;

  &.map {
    left: 10px;
    right: auto;
    display: block;
    font-size: 32px;
    color: $black;
  }

  &:before {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    content: "\f31e";
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (min-width: $screen-md) {
    div:hover > & {
      display: block;
    }
  }
}

.jtc-component-countdown-renderer {
  display: flex;
  align-items: center;
  gap: 8px;
}
.jtc-component-countdown-renderer .box {
  display: flex;
  flex-direction: column;
  padding: 8px;
  min-width: 100px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.1);
  align-items: center;
}
.jtc-component-countdown-renderer .value {
  font-size: 24px;
  font-weight: 800;
}


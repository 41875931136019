.jtc-component-jtc-cookie-management {
  position: fixed;
  bottom: 16px;
  background: white;
  max-width: 460px;
  width: calc(100% - 32px);
  left: 16px;
  padding: 20px 20px 0px;
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  z-index: 1300;
  opacity: 0;
  transition: opacity 0.2s;
}
.jtc-component-jtc-cookie-management.show {
  opacity: 1;
}
.jtc-component-jtc-cookie-management h2 {
  margin: 0 0 20px 0;
}
.jtc-component-jtc-cookie-management .heading {
  margin-bottom: 16px;
}
.jtc-component-jtc-cookie-management .title {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
}
.jtc-component-jtc-cookie-management .subtitle {
  display: block;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.04em;
}
.jtc-component-jtc-cookie-management .subtitle a {
  color: #34CFA1;
}
.jtc-component-jtc-cookie-management .content .jtc-component-jtc-checkbox-with-label__label-text {
  font-size: 13px;
}
.jtc-component-jtc-cookie-management .contentActions {
  margin-top: 16px;
  margin-bottom: 20px;
}
.jtc-component-jtc-cookie-management .actions {
  display: flex;
  gap: 12px;
}
.jtc-component-jtc-cookie-management .actions > * {
  flex: 1 1 100%;
}
.jtc-component-jtc-cookie-management .actions button {
  width: 100%;
}
.jtc-component-jtc-cookie-management .settings {
  text-align: center;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .jtc-component-jtc-cookie-management {
    bottom: 40px;
    left: 40px;
    padding: 24px 24px 4px;
    width: calc(100% - 48px);
  }
  .jtc-component-jtc-cookie-management .heading {
    margin-bottom: 20px;
  }
  .jtc-component-jtc-cookie-management .content {
    margin-top: 4px;
  }
  .jtc-component-jtc-cookie-management .contentActions {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .jtc-component-jtc-cookie-management .settings {
    margin-top: 2px;
  }
}
@media (min-width: 1200px) {
  .jtc-component-jtc-cookie-management {
    bottom: 60px;
    max-width: 560px;
    left: 60px;
    padding: 28px 28px 8px;
    width: calc(100% - 56px);
  }
  .jtc-component-jtc-cookie-management .heading {
    margin-bottom: 24px;
  }
  .jtc-component-jtc-cookie-management .title {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
  }
  .jtc-component-jtc-cookie-management .content {
    margin-top: 4px;
  }
  .jtc-component-jtc-cookie-management .contentActions {
    margin-top: 24px;
    margin-bottom: 20px;
  }
  .jtc-component-jtc-cookie-management .settings {
    margin-top: 6px;
  }
}


@import "../../../../styles/lib/variables";

.jtc-navigation-menu {
  &.item-children-wrapper {
    display: none;
    margin-top: 20px;
    @media (min-width: 992px) {
      position: absolute;
      padding-top: 44px;
      margin-top: 0;
      z-index: 1;

      .jtc-user-navigation-scroll {
        border-radius: 5px;
        margin: 15px 0;

        .item-children {
          margin: 0;
        }
      }
    }
  }

  .item-children {
    list-style: none;
    padding: 0;
    @media (min-width: 992px) {
      background: white;
      border-radius: 5px;
      padding: 30px 40px;
      box-shadow: 0 4px 40px 0 rgba(25, 53, 55, 0.08);
    }

    li {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

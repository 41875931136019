.mobile-fullscreen-modal-component-content-mobile {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 50000;
  background: #fff;
  will-change: transform;
  transform: translateY(100%);
  transition: transform 0.2s;
}
.mobile-fullscreen-modal-component-content-mobile.open {
  transform: translateY(0);
}
.mobile-fullscreen-modal-component-content-mobile .modal-wrapper {
  overflow: auto;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
}
.mobile-fullscreen-modal-component-content-mobile .header {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  position: relative;
  z-index: 10;
}
.mobile-fullscreen-modal-component-content-mobile .header .close-button {
  font-size: 20px;
  cursor: pointer;
  color: #193537;
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-fullscreen-modal-component-content-mobile .modal-navigation {
  padding: 0 16px 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  margin-top: auto;
}
.mobile-fullscreen-modal-component-content-mobile .modal-navigation span {
  display: flex;
  align-items: center;
}
.mobile-fullscreen-modal-component-content-mobile .modal-navigation .prev,
.mobile-fullscreen-modal-component-content-mobile .modal-navigation .next {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.mobile-fullscreen-modal-component-content-mobile .modal-navigation .next {
  margin-left: auto;
}
.mobile-fullscreen-modal-component-content-mobile .BrainhubCarouselItem {
  align-items: flex-start;
}
.mobile-fullscreen-modal-component-content-mobile .mobile-fullscreen-modal-content {
  padding: 16px;
}
.mobile-fullscreen-modal-component-content-mobile .score-inside {
  margin-top: 14px;
}
.mobile-fullscreen-modal-component-content-mobile .score-inside .jtc-component-cms-components-renderer {
  display: grid;
  grid-template-columns: 1fr 8fr;
  grid-template-rows: auto 1fr;
  row-gap: 24px;
}
.mobile-fullscreen-modal-component-content-mobile .score-inside .jtc-component-cms-components-renderer > div:nth-child(1) {
  grid-column: 1;
  grid-row: 1;
}
.mobile-fullscreen-modal-component-content-mobile .score-inside .jtc-component-cms-components-renderer > div:nth-child(2) {
  grid-column: 2;
  grid-row: 1;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 48px;
}
.mobile-fullscreen-modal-component-content-mobile .score-inside .jtc-component-cms-components-renderer > div:nth-child(2) * {
  margin: 0;
}
.mobile-fullscreen-modal-component-content-mobile .score-inside .jtc-component-cms-components-renderer > div {
  grid-column: 1/3;
}
.mobile-fullscreen-modal-component-content-mobile .carousel-footer {
  bottom: 24px;
}
@media (max-width: 992px) {
  .mobile-fullscreen-modal-component-content-mobile .carousel-footer {
    background: #fff;
    padding: 16px;
    bottom: 0;
  }
}


.jtc-component-footer-links .instagram-link {
  width: 110px;
  min-width: 110px;
  height: 34px;
  min-height: 34px;
  background-size: cover;
  background-position: center;
  margin-left: 0;
  margin-right: 17px;
}
@media (min-width: 1460px) {
  .jtc-component-footer-links .instagram-link {
    margin-left: 8.5px;
    margin-right: 21.25px;
  }
}
.jtc-component-footer-links .trustpilot-widget {
  margin-left: 17px;
}
@media (min-width: 1380px) {
  .jtc-component-footer-links .trustpilot-widget {
    margin-left: 34px;
  }
}
.jtc-component-footer-links .facebook-reviews {
  display: inline-flex;
  margin-right: 17px;
  white-space: nowrap;
}
.jtc-component-footer-links .facebook-reviews .facebook-link {
  width: 150px;
  min-height: 28px;
  background-size: cover;
  background-position: -15px center;
}
.jtc-component-footer-links .facebook-reviews .main-score {
  display: inline-flex;
  align-items: center;
  color: #fff;
  background-color: #558eff;
  -webkit-border-radius: 1em;
  border-radius: 1em;
  line-height: 1;
  padding: 0.4em 0.6em;
  font-size: 16px;
  font-weight: 500;
}
.jtc-component-footer-links .facebook-reviews .main-score i {
  padding-left: 4.25px;
}
.jtc-component-footer-links .facebook-reviews .reviews-count {
  display: flex;
  padding-left: 8.5px;
  padding-top: 2px;
  align-items: center;
  font-size: 12px;
  color: #558eff;
}

.line-breaker {
  display: block;
  width: 100%;
  margin: 17px 0 0;
}
@media (min-width: 1460px) {
  .line-breaker {
    display: none;
  }
}


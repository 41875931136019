@import "../../../styles/lib/variables";

.jtc-component-footer-partners-component {
  overflow-x: hidden;
  padding: 32px 32px 32px;
  position: relative;
  z-index: 1;

  &:after {
    clip-path: polygon(75% 0.2%, 0% 5%, 0% 100%, 74% 97%, 100% 100%, 100% 5%, 75% 0.2%);
    background: $color-green-light0;
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @media (min-width: $screen-md) {
      clip-path: polygon(78% 0.2%, 0% 8%, 0% 100%, 78% 92%, 100% 99%, 100% 8%, 78% 0.2%);
    }

  }

  @media (min-width: $screen-md) {
    overflow: initial;
    padding: 80px 32px 80px;
  }

  .partners-logos {
    justify-content: center;

    .partners-logos__list {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      flex: 1 1 auto;
      background: transparent;
      border: none;
      margin: 0;
      padding: 0;
      list-style: none;
      max-width: 720px;
      @media (min-width: $screen-md) {
        flex-wrap: nowrap;
      }

      .partner-box {
        display: flex;
        flex-basis: calc(33.333% - #{$indent__base});
        min-width: fit-content;
        text-align: center;
        margin: $indent__s;
        padding: 0;
        border: none;
        box-shadow: none;

        &:hover {
          background: none;

          .tooltip {
            > div {
              display: block;
              font-size: 15px;
            }
          }
        }

        > div {
          margin: 0 auto;
          background: transparent;
        }

        .partner-link {
          display: block;
        }

        img {
          display: block;
          height: $indent__xl;
          margin: 0 auto;
        }

        .tooltip {
          pointer-events: all;

          width: auto;
          height: auto;
          box-shadow: none;
          border-radius: 0;

          > div {
            display: none;
          }
        }

        &.hh {
          order: 2;

          img {
            min-width: 55px;
          }
        }

        &.dream-yacht-charter {
          order: 3;

          img {
            min-width: 80px;
          }
        }

        &.yacht-pool {
          order: 4;

          img {
            min-width: 80px;
          }
        }

        &.ultra {
          order: 5;

          img {
            min-width: 160px;
          }
        }

        @media (min-width: $screen-md) {
          flex: auto;
        }
      }
    }
  }
}

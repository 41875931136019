@import "../../../styles/lib/variables";

.jtc-component-cms-component-renderer {
  position: relative;

  &.flex.full-width > div.component-controls {
    width: auto;
  }

  .component-controls {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;

    a {
      color: $color-green-light0;
    }
  }

  &:hover .component-controls {
    display: block;

    &.gridcomponent,
    &.packlistcomponent {
      top: 40px;
    }

    &.herocomponent,
    &.tripherocomponent,
    &.tripfilterscomponent,
    &.teasercomponent {
      top: 120px;
    }
  }
}

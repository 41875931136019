@import "../variables/colors";

.jtc-table-wrapper {
  max-width: 100%;
  overflow: auto;
}

.jtc-table-title {
  color: $primary__color;
}

.jtc-table {
  border: 1px solid $color-gray80;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  color: $primary__color;

  caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
  }

  tr {
    background-color: #f8f8f8;
    border: 1px solid $color-gray96;
    padding: 0.35em;
  }

  td,
  th {
    padding: 0.625em;
    text-align: center;
  }

  th {
    font-size: 0.85em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  @media screen and (max-width: $screen-sm) {
    border: 0;

    caption {
      font-size: 1.3em;
    }

    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      display: none;
    }

    tr {
      border-bottom: 3px solid $color-gray96;
      display: block;
      margin-bottom: 0.625em;

      &:last-child {
        margin-bottom: 0;
      }
    }

    td {
      border-bottom: 1px solid $color-gray96;
      display: block;
      font-size: 0.8em;
      text-align: right;

      &::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.content-table {
  border-collapse: collapse;

  td,
  th {
    border: 1px solid black;
  }

  tr:first-child th,
  tr:first-child td {
    border-top: 0;
  }

  tr:last-child td {
    border-bottom: 0;
  }

  tr td:first-child,
  tr th:first-child {
    border-left: 0;
  }

  tr td:last-child,
  tr th:last-child {
    border-right: 0;
  }

  p {
    margin-top: 5px !important;
    margin-bottom: 5px;
  }
}

@import "../../../styles/lib/variables";

.jtc-component-jtc-checkbox-with-label {
  span{
    line-height: 1.5;
  }
  .jtc-checkbox {
    margin-inline: 8px;
  }

  &__label-text {
    font-size: 16px;
    color: $color-blue-dark;
    font-weight: bold;
  }
}

@import "../../../styles/lib/variables";

.jtc-component-scroll-top {
  position: fixed;
  display: none;
  bottom: 50px;
  right: 50px;
  height: 72px;
  width: 72px;
  background-color: $primary__color;
  border: 2px solid $primary__color__lighter;
  border-radius: 20px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 1100;
  align-items: center;
  justify-content: center;

  @media (min-width: $screen-md) {
    &.scroller-visible {
      display: flex;
    }
  }
}

.jtc-component-jtc-checkbox-with-label span {
  line-height: 1.5;
}
.jtc-component-jtc-checkbox-with-label .jtc-checkbox {
  margin-inline: 8px;
}
.jtc-component-jtc-checkbox-with-label__label-text {
  font-size: 16px;
  color: #001634;
  font-weight: bold;
}


.with-transparent-navbar .jtc-component-disruptor.show ~ .jtc-navigation-component {
  position: sticky;
  top: 0;
  margin-bottom: -88px;
}
@media (min-width: 992px) {
  .with-transparent-navbar .jtc-component-disruptor.show ~ .jtc-navigation-component {
    margin-bottom: -98px;
  }
}

.jtc-navigation-component.isNavigationOpen,
.with-transparent-navbar .jtc-component-disruptor.show ~ .jtc-navigation-component.isNavigationOpen {
  position: fixed;
}

.jtc-navigation-component {
  position: sticky;
  top: 0;
  z-index: 110;
  width: 100%;
  padding: 14px 0;
  background: #194648;
  transition: transform 200ms ease-in-out;
}
@media (min-width: 768px) {
  .jtc-navigation-component {
    padding: 20px 0;
  }
}
.jtc-navigation-component-headroom--pinned.isProfileNavigationOpen, .jtc-navigation-component-headroom--pinned.isNavigationOpen, .jtc-navigation-component-headroom--unpinned.isProfileNavigationOpen, .jtc-navigation-component-headroom--unpinned.isNavigationOpen {
  transform: none;
}
.jtc-navigation-component-headroom--pinned {
  transform: translateY(0%);
}
.jtc-navigation-component-headroom--unpinned {
  transform: translateY(-100%);
}
.with-transparent-navbar .jtc-navigation-component {
  background: transparent;
  position: fixed;
  top: 0;
}
.with-color-scheme.green:not(.with-transparent-navbar) .jtc-navigation-component {
  background-color: #194648;
}
.with-color-scheme.grey .jtc-navigation-component {
  background-color: #EAE3DC;
}
.jtc-navigation-component.customerArea {
  padding-bottom: 0;
}
.jtc-navigation-component.customerArea, .jtc-navigation-component.isSmallHeader {
  background: #194648;
}
.jtc-navigation-component.customerArea .navigation-container-wrapper, .jtc-navigation-component.isSmallHeader .navigation-container-wrapper {
  align-items: center;
}
.jtc-navigation-component .jtc-global-search {
  cursor: pointer;
}
@media (min-width: 992px) {
  .jtc-navigation-component .jtc-global-search {
    margin-right: 16px;
  }
}
.jtc-navigation-component .jtc-global-search svg {
  display: block;
}
.jtc-navigation-component .logo-container {
  min-width: 120px;
  margin-right: auto;
}
.jtc-navigation-component .logo-container img {
  max-width: 100%;
  max-height: 52px;
  height: 100%;
  display: block;
  transition: all 0.2s ease-in-out;
}
.jtc-navigation-component.isProfileNavigationOpen, .jtc-navigation-component.isNavigationOpen {
  transition: none;
}
.jtc-navigation-component.isProfileNavigationOpen {
  background-color: #194648;
}
.jtc-navigation-component.isProfileNavigationOpen .profile-item-container > .item-children-wrapper {
  display: block;
  z-index: 1;
}
.jtc-navigation-component.isNavigationOpen {
  background-color: #194648;
}
.jtc-navigation-component.isNavigationOpen .navigation-container {
  display: flex;
  z-index: 1;
}
.jtc-navigation-component .navigation-container {
  display: none;
}
@media (max-width: 991px) {
  .jtc-navigation-component .navigation-container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 80px;
    padding: 32px;
    background: #fff;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    justify-content: space-between;
  }
}
@media (min-width: 992px) {
  .jtc-navigation-component .navigation-container {
    display: block;
  }
}
.jtc-navigation-component .navigation-container-wrapper {
  display: flex;
  align-items: center;
}
@media (min-width: 992px) {
  .jtc-navigation-component .navigation-container-wrapper {
    max-width: 100%;
  }
}
.jtc-navigation-component .navigation-items {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 12px;
}
@media (min-width: 992px) {
  .jtc-navigation-component .navigation-items {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 16px;
  }
}
.jtc-navigation-component .profile-item-container {
  position: relative;
  margin-left: 16px;
}
@media (max-width: 991px) {
  .jtc-navigation-component .profile-item-container .jtc-navigation-menu .jtc-navigation-item-component {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .jtc-navigation-component .profile-item-container:hover > .item-children-wrapper {
    display: block;
  }
}
.jtc-navigation-component .profile-item-container .item-children-wrapper {
  display: none;
  padding-top: 23px;
  right: 0;
}
@media (max-width: 991px) {
  .jtc-navigation-component .profile-item-container .item-children-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 80px;
    margin-top: 0;
    background: #fff;
    overflow-y: auto;
    padding: 32px;
  }
}
.jtc-navigation-component .profile-item-container .item-children-wrapper a {
  white-space: nowrap;
}

.user-navigation-container-wrapper {
  background: #eae8e7;
  margin-top: 24px;
}
.user-navigation-container-wrapper.container {
  max-width: 100%;
  padding-inline: 0;
}
.user-navigation-container-wrapper .customer-navigation-renderer {
  padding-left: 16px;
  scroll-padding-left: 16px;
}
@media (min-width: 992px) {
  .user-navigation-container-wrapper .customer-navigation-renderer {
    padding-left: 32px;
    scroll-padding-left: 32px;
  }
}
.user-navigation-container-wrapper .jtc-navigation-menu {
  max-width: 100%;
}
.user-navigation-container-wrapper .jtc-navigation-menu .item-children {
  display: flex;
  border-radius: 0;
  padding: 0 16px 0 0;
  box-shadow: none;
  gap: 40px;
  background: transparent;
  margin: 0;
}
.user-navigation-container-wrapper .jtc-navigation-menu .item-children .jtc-navigation-item-component {
  padding-block: 16px;
  margin: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  white-space: nowrap;
}
.user-navigation-container-wrapper .jtc-navigation-menu .item-children .jtc-navigation-item-component.active {
  border-bottom: 4px solid #47EDBC;
}


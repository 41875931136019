@import "../../lib/variables";

@mixin fill() {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.filters-grid-item {
  overflow: hidden;

  @include fill();

  div.flex {
    height: 100%;
  }

  .new-item {
    position: absolute;
    left: 3px;
    top: 5px;
    z-index: 1;
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 0;

    @media (min-width: $screen-md) {
      left: 5px;
      font-size: 30px;
    }
  }

  .new-item-triangle {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 50px 0 0;
    border-color: #df5193 transparent transparent transparent;

    @media (min-width: $screen-md) {
      border-width: 100px 100px 0 0;
    }
  }

  .trip-vendor-logo {
    position: absolute;
    right: 5px;
    bottom: 5px;
    display: block;
    z-index: 1;

    img {
      height: 22px;
      width: auto;
    }

    @media (min-width: $screen-md) {
      img {
        height: 35px;
      }
    }
  }

  .trip-vendor-logo-triangle {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 70px 70px;
    border-color: transparent transparent white transparent;

    @media (min-width: $screen-md) {
      border-width: 0 0 100px 100px;
    }
  }

  &__inside {
    @include fill();
    background: transparentize($primary__color, 0.25);
    color: white;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    .tags {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      margin-bottom: 5px;
    }
    .destination-name,
    .destination-country {
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
    }

    .destination-name {
      font-size: $font-size__xs * 1.2;
      margin: 15px 0 10px;
      letter-spacing: 3px;

      @media (min-width: $screen-sm) {
        font-size: 30px;
      }
    }

    .destination-country {
      font-size: $font-size__xs;

      @media (min-width: $screen-sm) {
        font-size: 20px;
      }
    }
  }

  .sold-out-triangle {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.8) transparent
      transparent transparent;
    background: transparent;
    z-index: 100;
    pointer-events: none;

    border-width: 140px 140px 0 0;

    @media (min-width: $screen-xs) {
      border-width: 255px 255px 0 0;
    }

    @media (min-width: $screen-sm) {
      border-width: 220px 220px 0 0;
    }

    @media (min-width: $screen-xl) {
      border-width: 268px 268px 0 0;
    }
  }

  .sold-out-text {
    display: block;
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 24px;
    line-height: 1;
    color: white;
    z-index: 101;
    text-transform: uppercase;
    font-weight: bold;

    @media (min-width: $screen-xs) {
      top: 20px;
      left: 20px;
      font-size: 32px;
    }

    @media (min-width: $screen-xl) {
      top: 20px;
      left: 20px;
      font-size: 48px;
    }
  }
}

@import "../../../styles/lib/variables";
@import "../../../styles/lib/utillities";

.jtc-component-jtc-dialog,
.jtc-dialog {
  $dialog_max-width: 560px;

  @media (max-width: $dialog_max-width) {
    align-items: end !important;

    > [role="document"] {
      margin: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  h2 {
    @extend h4;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 0;

    .title {
      margin-right: auto;
    }

    .close-button {
      margin-left: auto;
      margin-top: 2px;
      cursor: pointer;
    }
  }

  [role="document"] {
    width: 100%;
    max-width: $dialog_max-width;

    &,
    > form {
      .jtc-dialog-title,
      > div:nth-child(1) {
        padding: 24px 24px 24px 24px;

        @media (min-width: $screen-xs) {
          padding: 40px 40px 32px 40px;
        }

        &.yacht-standard-modal-title {
          padding-bottom: 0;
        }

        .title {
          margin-bottom: 0;
        }
      }

      .jtc-dialog-content,
      > div:nth-child(2) {
        padding: 0 24px 24px 24px;

        @media (min-width: $screen-xs) {
          padding: 0 40px 32px 40px;
        }

        .jtc-dialog-text {
          color: $primary__color !important;
          font-size: 15px !important;
          font-weight: 500 !important;
        }

        .jtc-dialog-text_error {
          color: $color-error !important;
        }
      }

      .jtc-dialog-actions,
      > div:nth-child(3) {
        margin: 0;
        padding: 0 24px 24px 24px;
        justify-content: center;
        gap: 16px;

        @media (max-width: $screen-xxs-min) {
          flex-direction: column;
        }

        @media (min-width: $screen-xs) {
          padding: 0 40px 40px 40px;
        }

        button,
        .button-glow {
          margin: 0;
          width: 100%;
        }
      }
    }
  }
}

.jtc-dialog-checkbox {
  margin-top: 16px;
}

.jtc-component-jtc-dialog-wide {
  @media (min-width: $screen-md) {
    [role="document"] {
      max-width: 1200px;
    }
  }

  > div:nth-of-type(2) {
    @media (max-width: $screen-sm) {
      margin: 10px;

      > div:first-of-type {
        padding: 10px;
      }

      > div:nth-of-type(2) {
        padding: 0 10px 24px;
      }
    }
  }
}

.jtc-component-jtc-dialog-wide-desktop {
  > div:nth-of-type(2) {
    @media (min-width: $screen-md) {
      max-width: none;
    }
  }
}

.jtc-component-jtc-dialog-extra-wide {
  > div:nth-of-type(2) {
    @media (max-width: $screen-sm) {
      min-width: calc(100vw - 20px);
    }
  }
}

.copy-content-textarea {
  border: 1px solid $color-gray-darken1;
  width: 100%;
  resize: none;
  height: 80px;
  margin-top: $indent__s;

  &.single-line {
    height: 24px;
    font-size: 16px;
  }
}

.jtc-language-switcher-container {
  cursor: pointer;
  margin-left: 16px;
  text-align: right;
}
.jtc-language-switcher-container.is-mobile {
  margin-top: 92px;
}
@media (max-width: 991px) {
  .jtc-language-switcher-container:not(.is-mobile) {
    display: none;
  }
}
@media (min-width: 992px) {
  .jtc-language-switcher-container.is-mobile {
    display: none;
  }
}
.jtc-language-switcher-container .label {
  line-height: 20px;
  color: #A9A19A;
}
@media (min-width: 992px) {
  .jtc-language-switcher-container .label {
    display: none;
  }
}
.jtc-language-switcher-container .flag-icon img {
  display: block;
  height: 24px;
}
.jtc-language-switcher-container .tag-as-button {
  border: 2px solid #47EDBC;
  border-radius: 50px;
  padding: 6px 16px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  margin-right: 4px;
}


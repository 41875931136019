@import "../../../../styles/lib/variables";
@import "../../../../styles/lib/utillities";

$topnavbar__icon-size: 11px;

@media (max-width: $screen-md) {
  .jtc-component-top-navbar-primary-link {
    .jtc-component-top-navbar-user-info {
      display: none;
    }
  }
}

.jtc-component-top-navbar-user-info {


  .badge {
    position: absolute;
    top: -5px;
    right: -10px;
    padding: 2px 10px;
    border-radius: 10px;
    text-transform: uppercase;
    background-color: $color-pink-light0;
    color: $color-white;
    font-size: $font-size__xs + 2;
    font-weight: $font-weight__medium;
    box-shadow: 0 2px 2px 0 rgba($color-black, 0.2);

    @media (min-width: $screen-md) {
      right: -24px;
    }

    &:after {
      display: none;
      font-family: "Font Awesome 5 Pro";
      font-weight: 300;
      margin-left: 4px;
      content: "\f0e0";

      @media (min-width: $screen-md) {
        display: inline;
      }
    }
  }

  .active & {
    & {
      &:after {
        top: 105%;
        left: 50%;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 8px solid $color-gray92;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        transform: translateX(-50%);
        opacity: 1;
        padding: 0;
        transition: opacity 0.1s;
      }
    }
  }

  &.login {
    position: absolute;
    bottom: 0;
    top: 0;
    right: -20px;
    display: flex;
    align-items: center;

    @include ie-media-support() {
      height: 20px;
      top: 13px;
      display: block;

      a {
        margin-left: 5px;
      }
    }

    @media (min-width: $screen-md) {
      display: none;
    }

    .login-logout-button {
      font-size: 13px;
      list-style: none;
      display: flex;
      margin: 0 $indent__s 0 $indent__base;
      padding: 1px $indent__xs;
      border: 1px solid $color-white;
      text-transform: uppercase;
      font-weight: $font-weight__bold;
      line-height: 15px;
      cursor: pointer;

      &:before {
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        content: "\f023";
        font-size: $topnavbar__icon-size - 2px;
        color: $color-white;
        line-height: 15px;
        margin-right: 5px;
      }

      a {
        color: $color-white;
      }
    }
  }

  .avatar {
    position: relative;
    -webkit-transition: all 0.1s linear;
    -o-transition: all 0.1s linear;
    transition: all 0.1s linear;

    border-radius: 50%;
    background-size: cover;
    background-position: center;
    width: 36px;
    height: 36px;

    .initials {
      display: none;
      text-transform: uppercase;
    }

    &.no-avatar.has-initials {
      .initials {
        position: absolute;
        top: 0;
        overflow: hidden;
        display: block;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        background-color: $color-blue-dark50;
        text-align: center;

        span {
          position: absolute;
          color: $color-white;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: $font-size__xs;
          font-weight: $font-weight__heavy;

          @media (min-width: $screen-sm) {
            font-size: $font-size__base;
          }

          @include ie-media-support() {
            left: 50%;
          }
        }
      }
    }

    &.no-avatar:not(.has-initials) {
      &:before {
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        color: $color-white;
        content: "\F2BD";
        font-size: 34px;
        line-height: 34px;
        margin-right: 5px;

        @media (min-width: $screen-md) {
          font-size: 50px;
          line-height: 50px;
        }
      }
    }
  }
}

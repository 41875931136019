@import "../../../styles/lib/variables";

.tooltip-wrapper-element {
  display: inline-flex;
  position: relative;

  div[role="tooltip"] {
    opacity: 1;
    font-size: 13px;
    min-width: 200px;
    z-index: 100000;
    text-align: center;
    font-weight: normal;

    &[x-placement="top"] {
      > div {
        box-shadow: 0 -4px 40px 0 rgba($primary__color, .1);
      }
    }

    > div {
      margin: 8px 0;
      font-size: 13px;
      background-color: $white;
      border: 1px solid $shades-medium__color;
      font-weight: $font-weight__medium;
      line-height: 20px;
      padding: 12px;
      box-shadow: 0 4px 40px 0 rgba($primary__color, .1);


      &,
      a {
        color: $black;
      }

      a {
        text-decoration: underline;
      }
    }
  }
}

.jtc-component-jtc-tooltip {
  .tooltip-icon {
    svg {
      display: block;
    }
  }
}

@import "../../../styles/lib/variables";

.jtc-component-not-found {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;

  a {
    margin: 0 auto;
  }
}

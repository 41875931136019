@import "../../../styles/lib/variables";

.jtc-social-media-component {
  display: flex;
  flex-direction: column;

  .title {
    text-align: center;
  }

  .social-media-component-items {
    display: flex;
    gap: 24px 8px;
    align-items: center;
    justify-content: center;
    @media (min-width: 620px) {
      gap: 20px;
      flex-wrap: nowrap;
    }
  }

  .social-media-component-link {
    display: flex;
    align-items: center;
    padding: 0 12px;
    flex-direction: column;
    width: calc(50% - 8px);
    @media (min-width: 620px) {
      width: auto;
    }

    .social-media-component-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba($primary__color, 0.12);
      width: 52px;
      height: 52px;
      backdrop-filter: blur(50px);
      margin-bottom: 8px;

      clip-path: $irregularCirlcle_polygon;

      @media (min-width: $screen-xs) {
        width: 72px;
        height: 72px;
      }

      > span {
        display: flex;
      }
    }

    .label {
      text-align: center;
    }
  }

}

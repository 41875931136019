@import "../../../styles/lib/variables";

.jtc-component-itinerary-notice {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  background: $color-white;
  box-shadow: 0 4px 40px 0 rgba(25, 53, 55, 0.08);
  border-radius: 8px;
  overflow: hidden;
  border-left: 8px solid $theme__color__primary;

  .itinerary-notice-icon {
    height: 100%;
    padding: 16px 20px 16px 20px;

    &.has-tooltip {
      cursor: pointer;
    }

    img {
      display: block;
    }
  }

  .itinerary-notice-content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 16px 20px 16px 0;
    flex-grow: 1;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.52px;

    h6 {
      margin-bottom: 0;
    }
  }

  .target-section {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

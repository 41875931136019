@import "../../../styles/lib/variables";

.jtc-component-disruptor {
  display: none;

  position: relative;
  padding: 16px 0;
  z-index: 10;

  &.show {
    display: block;
  }

  &.symetric {
    .disruptor-inside {
      .title-image {
        display: flex;
        flex: 1;
        justify-content: flex-start;
      }

      .content-countdown {
        display: flex;
        flex: 1;
        justify-content: center;
        margin-right: initial;
      }

      .actions {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }
    }
  }

  .disruptor-inside {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    font-weight: 400;

    @media (min-width: $screen-sm) {
      gap: 40px;
      flex-direction: row;
    }

    .title-image {
      @media (max-width: $screen-sm) {
        width: 100%;
      }

      .image {
        width: 120px;
        aspect-ratio: 2/1;
        @media (max-width: $screen-sm) {
          margin: 0 auto;
        }
      }

      .title {
        margin: 0 0 16px 0;
        text-align: left;

        @media(min-width: $screen-sm) {
          margin-bottom: 0;
        }
      }
    }

    .content-countdown {
      margin-right: auto;

      &:not(.countdown){
        padding-right: 24px;
      }



      p {
        padding: 0;
        margin: 0;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 40px;

      .button{
        @media (max-width: $screen-sm) {
          margin-top: 16px;
        }
      }

      .close-disruptor {
        color: white;
        cursor: pointer;
        font-size: 24px;

        @media (max-width: $screen-sm) {
          position: absolute;
          top: 8px;
          right: 16px;
        }
      }
    }

    a {
      color: inherit;
      font-weight: bolder;
      text-decoration: none;
    }
  }
}

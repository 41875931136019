.jtc-navigation-item-component {
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .jtc-navigation-item-component {
    margin-bottom: 0;
  }
}
@media (max-width: 991px) {
  .jtc-navigation-item-component.top-level {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
.jtc-navigation-item-component a {
  display: flex;
  align-items: center;
  margin: 0;
  transition: color 0.1s ease-in-out;
}
@media (max-width: 991px) {
  .jtc-navigation-item-component.isSpecial {
    grid-column-start: 2;
    grid-row-start: 1;
  }
}
.jtc-navigation-item-component.isSpecial a {
  color: #193537;
}
.jtc-navigation-item-component.isSpecial a > span {
  text-align: center;
}
.jtc-navigation-item-component:not(.isSpecial) a {
  color: #fff;
}
.isNavigationOpen .jtc-navigation-item-component:not(.isSpecial) a, .isProfileNavigationOpen .jtc-navigation-item-component:not(.isSpecial) a {
  color: #193537;
}
.jtc-navigation-item-component .icon {
  display: flex;
  margin-left: 2px;
}
@media (min-width: 992px) {
  .jtc-navigation-item-component .icon {
    display: none;
  }
}
.customerArea .jtc-navigation-item-component .icon svg path, .isNavigationOpen .jtc-navigation-item-component .icon svg path, .isProfileNavigationOpen .jtc-navigation-item-component .icon svg path, .with-color-scheme.grey .jtc-navigation-item-component .icon svg path {
  stroke: #193537;
}
.jtc-navigation-item-component.submenu-item {
  margin-bottom: 20px;
}
.jtc-navigation-item-component.submenu-item a {
  color: #A9A19A;
}
.jtc-navigation-item-component.submenu-item a:hover {
  color: #325F61;
}
.user-navigation-container-wrapper .jtc-navigation-item-component.submenu-item a {
  color: #193537;
}
.user-navigation-container-wrapper .jtc-navigation-item-component.submenu-item a:hover {
  color: #325F61;
}
.jtc-navigation-item-component.isLast {
  order: 99;
}
.jtc-navigation-item-component.isToggleItem > .item-children-wrapper {
  display: block;
}
@media (min-width: 992px) {
  .jtc-navigation-item-component.hasChild:hover > .item-children-wrapper {
    display: block;
  }
}


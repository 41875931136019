@import "../../../styles/lib/variables";
.jtc-component-footer-links {
  .instagram-link {
    width: 110px;
    min-width: 110px;
    height: 34px;
    min-height: 34px;
    background-size: cover;
    background-position: center;
    margin-left: 0;
    margin-right: $indent__base;

    @media (min-width: $footer-links-two-lines-breakpoint) {
      margin-left: $indent__s;
      margin-right: $indent__m;
    }
  }

  .trustpilot-widget {
    margin-left: $indent__base;

    @media (min-width: 1380px) {
      margin-left: $indent__xl;
    }
  }

  .facebook-reviews {
    display: inline-flex;
    margin-right: $indent__base;
    white-space: nowrap;

    .facebook-link {
      width: 150px;
      min-height: 28px;
      background-size: cover;
      background-position: -15px center;
    }

    .main-score {
      display: inline-flex;
      align-items: center;
      color: #fff;
      background-color: #558eff;
      -webkit-border-radius: 1em;
      border-radius: 1em;
      line-height: 1;
      padding: 0.4em 0.6em;
      font-size: 16px;
      font-weight: $font-weight__medium;

      i {
        padding-left: $indent__xs;
      }
    }

    .reviews-count {
      display: flex;
      padding-left: $indent__s;
      padding-top: 2px;
      align-items: center;
      font-size: 12px;
      color: #558eff;
    }
  }
}

.line-breaker {
  display: block;
  width: 100%;
  margin: $indent__base 0 0;

  @media (min-width: $footer-links-two-lines-breakpoint) {
    display: none;
  }
}

.jtc-component-youtube-link {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  background: #e42a26;
  color: white;
  height: 24px;
  padding: 0 7px;
}
.jtc-component-youtube-link i {
  margin-right: 7px;
  font-size: 16px;
}
.jtc-component-youtube-link span {
  font-size: 12px;
  font-weight: 600;
  font-family: "YouTube Noto", Roboto, arial, sans-serif;
}


//
//  Color variables
//  _____________________________________________

$color-white: #fff;
$color-black: #000;

$color-gray19: #303030;
$color-gray20: #333;
$color-gray34: #575757;
$color-gray37: #5e5e5e;
$color-gray40: #666;
$color-gray43: #6d6d6d;
$color-gray46: #757575;
$color-gray52: #858585;
$color-gray55: #8c8c8c;
$color-gray56: #8f8f8f;
$color-gray60: #999;
$color-gray62: #9e9e9e;
$color-gray64: #a3a3a3;
$color-gray68: #adadad;
$color-gray76: #c2c2c2;
$color-gray78: #c7c7c7;
$color-gray79: #c9c9c9;
$color-gray80: #ccc;
$color-gray82: #d1d1d1;
$color-gray89: #e3e3e3;
$color-gray90: #e5e5e5;
$color-gray91: #e8e8e8;
$color-gray92: #ebebeb;
$color-gray93: rgba(223, 224, 225, 0.2);
$color-gray94: #f0f0f0;
$color-gray95: #f2f2f2;
$color-gray96: #ddd;
$color-white-smoke: #f5f5f5;
$color-white-dark-smoke: #efefef;
$color-white-fog: #f8f8f8;

$color-gray-light0: #f6f6f6;
$color-gray-light01: #f4f4f4;
$color-gray-light1: #e5e5e5;
$color-gray-light2: #bbb;
$color-gray-light3: #aeaeae;
$color-gray-light4: #cecece;
$color-gray-light5: #c1c1c1;
$color-gray-light6: #c5c5c5;
$color-gray-light7: #979797;

$color-gray-middle1: #e4e4e4;
$color-gray-middle2: #c6c6c6;
$color-gray-middle3: #7e807e;
$color-gray-middle4: #6e716e;
$color-gray-middle5: #707070;

$color-gray-darken0: #eee;
$color-gray-darken1: #e2e2e2;
$color-gray-darken2: #cdcdcd;
$color-gray-darken3: #555;
$color-gray-darken4: #494949;

$color-blue-dark: #001634;
$color-blue-dark10: #e4e6e9;
$color-blue-dark20: #d7d9dc;
$color-blue-dark50: #7e8998;
$color-blue20: #1d527b;
$color-blue-middle1: #33455d;

$color-blue-lighter10: #a3bcc5;

$color-bright-blue: #43d0e8;

$color-green: #95e636;
$color-green-light0: #47EDBC;
$color-green-light1: #d3ffcf;
$color-green-light2: #34CFA1;
$color-green-dark1: #19d685;

$color-pink: #ff0073;
$color-pink-2: #f925a2;
$color-pink-light0: #fe6178;

$color-error: #f44336;
$color-error-1: #FF554A;
$color-red: #ff0000;

$color-warning: #feac49;
$color-yellow: #FFD12F;

$color-violet: violet;

//
//  Color nesting
//  ---------------------------------------------

$shades-extra-light__color: #F9F6F3;
$shades-light__color: #F3EEE8;
$shades-medium__color: #EAE3DC;
$shades-dark__color: #A9A19A;
$sun__color: #FFD12F;

//Colors @TODO - remove after tweaks
$black: #151515;
$white: #fff;
$gray: #95989a;
$color-gray-background: #efefef;

$theme__color__primary: $color-green-light0;

$primary__color: #193537;
$secondary__color: rgba($primary__color, 0.60);

$primary__color__lighter: #325F61;
$primary__color__medium: #194648;

$page__background-color: $shades-extra-light__color;

$color-modal-background: rgba($primary__color, 0.85);

$chat-send-button-bg: $primary__color;
$chat-send-button-bg__disabled: #cbd8dd;

$skippers-details-background: $primary__color;
$skippers-details-color: $color-white;


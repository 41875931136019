@import "../../styles/lib/variables";

.jtc-page-cms {
  .loading {
    height: 90vh;
    align-items: center;
    justify-content: center;
  }
}

.jtc-component-cms-page-wrapper {
  padding-top: $subnavbar_height;

  .with-hidden-submenu &,
  .with-transparent-navbar &,
  .with-color-scheme & {
    padding: 0;
  }

  .with-color-scheme.green & {
    background-color: $primary__color;
    padding-bottom: 80px;
    margin-bottom: -40px;
  }
}

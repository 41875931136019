.jtc-component-share-buttons {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
}
.jtc-component-share-buttons a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  width: 52px;
  height: 52px;
  backdrop-filter: blur(50px);
  clip-path: polygon(100% 49.367%, 100% 49.367%, 99.25% 56.985%, 97.095% 64.431%, 93.679% 71.557%, 89.147% 78.213%, 83.643% 84.252%, 77.31% 89.526%, 70.293% 93.886%, 62.735% 97.184%, 54.781% 99.271%, 46.575% 100%, 46.575% 100%, 38.561% 99.271%, 31.128% 97.184%, 24.337% 93.886%, 18.251% 89.526%, 12.932% 84.252%, 8.442% 78.213%, 4.841% 71.557%, 2.193% 64.431%, 0.559% 56.985%, 0% 49.367%, 0% 49.367%, 0.885% 41.784%, 3.404% 34.434%, 7.356% 27.451%, 12.54% 20.967%, 18.754% 15.115%, 25.797% 10.028%, 33.466% 5.841%, 41.561% 2.685%, 49.879% 0.693%, 58.219% 0%, 58.219% 0%, 66.099% 0.693%, 73.168% 2.685%, 79.422% 5.841%, 84.855% 10.028%, 89.465% 15.115%, 93.246% 20.967%, 96.194% 27.451%, 98.306% 34.434%, 99.576% 41.784%, 100% 49.367%);
}
@media (min-width: 576px) {
  .jtc-component-share-buttons a {
    width: 72px;
    height: 72px;
  }
}
.jtc-component-share-buttons a > span {
  display: flex;
}


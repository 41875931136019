.show-gallery-button {
  position: absolute;
  display: none;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: #fff;
  opacity: 0.6;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  border: none;
  font-size: 18px;
  line-height: 1;
  padding: 0;
  z-index: 1;
}
.show-gallery-button.map {
  left: 10px;
  right: auto;
  display: block;
  font-size: 32px;
  color: #151515;
}
.show-gallery-button:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f31e";
}
.show-gallery-button:focus {
  outline: none;
  box-shadow: none;
}
@media (min-width: 992px) {
  div:hover > .show-gallery-button {
    display: block;
  }
}


.jtc-component-jtc-improved-slider {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.jtc-component-jtc-improved-slider.slider-in-modal {
  padding: 0 !important;
}
.jtc-component-jtc-improved-slider .carousel-wrapper {
  position: relative;
}
.jtc-component-jtc-improved-slider .carousel-wrapper, .jtc-component-jtc-improved-slider .carousel-wrapper > div:not(.carousel-footer) {
  height: 100%;
  width: 100%;
}
.jtc-component-jtc-improved-slider .carousel-footer {
  position: absolute;
  bottom: 24px;
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
.jtc-component-jtc-improved-slider .carousel-footer .play-pause {
  vertical-align: bottom;
  height: 6px;
  font-size: 7px;
  color: #fff;
  margin-right: 6px;
  text-shadow: 1px 3px 4px rgba(0, 0, 0, 0.4);
  display: block;
}
.jtc-component-jtc-improved-slider .carousel-footer .jtc-improved-slider-thumbnail {
  width: 50px;
  height: 50px;
}
.jtc-component-jtc-improved-slider .BrainhubCarousel, .jtc-component-jtc-improved-slider .BrainhubCarousel__trackContainer, .jtc-component-jtc-improved-slider .BrainhubCarousel__track, .jtc-component-jtc-improved-slider .BrainhubCarouselItem {
  height: auto;
  width: 100%;
}
.jtc-component-jtc-improved-slider .BrainhubCarouselItem > div {
  width: 100%;
}
.jtc-component-jtc-improved-slider .BrainhubCarousel__track li {
  position: static;
}
.jtc-component-jtc-improved-slider .BrainhubCarousel__dots {
  display: flex;
  gap: 12px;
}
.jtc-component-jtc-improved-slider .BrainhubCarousel__dots .jtc-improved-slider-dot,
.jtc-component-jtc-improved-slider .BrainhubCarousel__dots .BrainhubCarousel__dot {
  margin: 0;
  padding: 0;
  width: 6px;
  height: 6px;
  line-height: 6px;
  background: #EAE3DC;
  border-radius: 50%;
  transition: background 0.2s;
  display: inline-block;
  opacity: 1;
}
.jtc-component-jtc-improved-slider .BrainhubCarousel__dots .jtc-improved-slider-dot--selected,
.jtc-component-jtc-improved-slider .BrainhubCarousel__dots .BrainhubCarousel__dot--selected {
  background-color: #47EDBC;
  box-shadow: 0 0 0 3px #47EDBC;
}
.jtc-component-jtc-improved-slider .BrainhubCarousel__dots .jtc-improved-slider-dot:before,
.jtc-component-jtc-improved-slider .BrainhubCarousel__dots .BrainhubCarousel__dot:before {
  display: none;
}
.jtc-component-jtc-improved-slider .BrainhubCarousel__dots .jtc-improved-slider-dot:hover,
.jtc-component-jtc-improved-slider .BrainhubCarousel__dots .BrainhubCarousel__dot:hover {
  background: #325F61;
  box-shadow: 0 0 0 3px #325F61;
}
.jtc-component-jtc-improved-slider .BrainhubCarousel__thumbnail {
  padding-left: 3px;
  padding-right: 3px;
}
.jtc-component-jtc-improved-slider .BrainhubCarousel__thumbnail .tooltip-wrapper {
  visibility: hidden;
}
@media (min-width: 992px) {
  .jtc-component-jtc-improved-slider .BrainhubCarousel__thumbnail .tooltip-wrapper {
    visibility: visible;
  }
}
.jtc-component-jtc-improved-slider .BrainhubCarousel__thumbnail--selected .jtc-improved-slider-dot, .jtc-component-jtc-improved-slider .BrainhubCarousel__thumbnail--selected .jtc-improved-slider-dot:hover {
  border-color: rgb(255, 255, 255);
  background: rgb(255, 255, 255);
}
.jtc-component-jtc-improved-slider.arrows-position-top .jtc-improved-slider-arrow {
  align-self: flex-start;
  margin-top: 42px;
}
.jtc-component-jtc-improved-slider .jtc-improved-slider-arrow {
  position: absolute;
  z-index: 10;
}
.jtc-component-jtc-improved-slider .jtc-improved-slider-arrow.arrow-direction-left {
  left: 8.5px;
}
@media (min-width: 992px) {
  .jtc-component-jtc-improved-slider .jtc-improved-slider-arrow.arrow-direction-left .left-arrow-shape.default {
    width: 52px;
    height: 52px;
  }
}
.jtc-component-jtc-improved-slider .jtc-improved-slider-arrow.arrow-direction-right {
  right: 8.5px;
}
@media (min-width: 992px) {
  .jtc-component-jtc-improved-slider .jtc-improved-slider-arrow.arrow-direction-right .right-arrow-shape.default {
    width: 52px;
    height: 52px;
  }
}
.jtc-component-jtc-improved-slider:not(.arrows-variant-floating) .carousel-wrapper {
  padding-left: 32px;
  padding-right: 32px;
}
.jtc-component-jtc-improved-slider:not(.arrows-variant-floating) .jtc-improved-slider-arrow.arrow-direction-left {
  left: 0;
}
.jtc-component-jtc-improved-slider:not(.arrows-variant-floating) .jtc-improved-slider-arrow.arrow-direction-right {
  right: 0;
}
.jtc-component-jtc-improved-slider.no-arrows .carousel-wrapper {
  padding-left: 0;
  padding-right: 0;
}
.jtc-component-jtc-improved-slider.with-thumbnails .carousel-footer {
  position: static;
  width: 100%;
  overflow: auto;
}
.jtc-component-jtc-improved-slider.with-thumbnails.arrows-position-center .jtc-improved-slider-arrow {
  margin-top: -35px;
}


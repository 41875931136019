@import "../../../styles/lib/variables";

.jtc-component-footer-sitemap {
  position: relative;
  display: block;
  background: $primary__color__medium;
  padding-top: 80px;
  padding-bottom: 16px;
  margin-top: -30px;

  @media (min-width: $screen-xs) {
    padding-bottom: 40px;
  }

  .footer-logo {
    margin-bottom: 40px;
    @media (min-width: $screen-md) {
      margin-bottom: 64px;
    }
  }

  ul {
    padding: 0;
    background-color: transparent;
    list-style: none;
    flex: 1 1 auto;
    width: 100%;
    margin: 0;

    @media (min-width: $screen-xs) {
      width: auto;
    }

    li {
      color: $color-white;
      padding: 0;
      margin-bottom: 16px;

      &.list-header {
        padding: 0;
        color: $color-yellow;

        h4 {
          font-size: 18px;
          margin-bottom: 16px;
        }
      }

      a {
        color: $color-white;
      }
    }
  }

  .container {
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;
    @media (min-width: $screen-md) {
      flex-wrap: nowrap;
    }

    &.components {
      margin-bottom: 64px;
      gap: 64px;
    }
  }

  .jtc-component-newsletter-signup-component {
    width: 100%;
    max-width: 432px;
    text-align: left;

    .description {
      padding: 0;
      color: $color-yellow;
      font-size: 18px;
      margin-bottom: 16px;
      font-weight: 800;
    }
  }

  .jtc-social-media-component {
    .title {
      padding: 0;
      color: $color-yellow;
      font-size: 18px;
      margin-bottom: 16px;
      font-weight: 800;
      text-align: left
    }

    .social-media-component-items {
      justify-content: flex-start;

      .social-media-component-icon {
        background: rgba($color-white, 0.12);
      }

      .label {
        color: $color-white;
      }
    }
  }
}


.jtc-social-links-item-component {
  @media (max-width: 991px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .jtc-social-links-component {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 24px;
  }
}

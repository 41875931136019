.jtc-navigation-toggle-component {
  display: flex;
  order: 2;
  margin-left: 24px;
}
@media (min-width: 992px) {
  .jtc-navigation-toggle-component {
    display: none;
  }
}


@use "sass:math" as math;

@import "../variables";
@import "../utillities/typography";


@mixin switcher($max-width: 400px, $flex-direction: row, $type: null) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background: rgba($shades-dark__color, 0.24);
  //backdrop-filter: blur(50px);
  border-radius: 8px;

  max-width: $max-width;
  margin: 0 auto;

  .with-color-scheme.green {
    background: rgba($color-white, 0.16);
  }

  .disabled {
    opacity: .4;
  }

  .tooltip-wrapper-element{
    > div{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  }

  .switcher-item{
    display: flex;
  }

  .vertical-layout-switcher-switcher-button,
  .view-type,
  .switcher-item-wrapper {
    @include button-reset();
    flex: 1;
    text-align: center;
    margin: 0;
    cursor: pointer;
    color: $primary__color;
    flex-direction: $flex-direction;
    max-width: 50%;
    align-items: center;
    padding: 8px;
    display: flex;
    justify-content: center;

    .title {
      @if $type == label {
        @extend .label;
      } @else {
        margin-bottom: 0;
      }
    }

    .subtitle {
      color: rgba($primary__color, 60%);
      font-family: $font-family__sans-serif;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
    }

    .with-color-scheme.green & {
      color: $color-white;
    }

    &.active,
    &.selected {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;

      background: $sun__color;
      box-shadow: 0px 0px 12px rgba($sun__color, 0.5);
      border-radius: 8px;

      .with-color-scheme & {
        color: $primary__color;
      }
    }

    &.destinations {
      path {
        stroke: $primary__color;
      }

      &.selected {
        path {
          stroke: $primary__color;
        }
      }

      .with-color-scheme.green & {
        path {
          stroke: $shades-extra-light__color;
        }

        &.selected {
          path {
            stroke: $primary__color;
          }
        }
      }
    }

    &.date {
      path {
        stroke: $primary__color
      }

      &.selected {
        path {
          stroke: $primary__color
        }
      }

      .with-color-scheme.green & {
        path {
          stroke: $shades-extra-light__color
        }

        &.selected {
          path {
            stroke: $primary__color
          }
        }
      }
    }
  }
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: math.div($height, $width) * 100%;
    pointer-events: none;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin overlay($background, $opacity) {
  position: relative;
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background: $background;
    opacity: $opacity;
  }
}

@mixin overlay-gradient(
  $color-top,
  $position-top,
  $color-bottom,
  $position-bottom
) {
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-image: linear-gradient(
                    -180deg,
                    $color-top $position-top,
                    $color-bottom $position-bottom
    );
  }
}

@mixin sticky($top) {
  position: sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  top: $top;
}

@mixin stickyWithoutTop() {
  position: sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
}

@mixin button-reset() {
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  margin: 0;
}

@mixin big-heading($font-size, $ratio: 1.5, $mid-ratio: 1.2) {
  .big-heading & {
    .heading-title {
      font-size: $font-size;

      @media (max-width: $screen-md) {
        font-size: math.div($font-size, 1.2);
      }

      @media (max-width: $screen-xs) {
        font-size: math.div($font-size, $ratio);
      }
    }
  }
}

@mixin medium-heading($font-size, $ratio: 1.5, $mid-ratio: 1.5) {
  .medium-heading & {
    .heading-title {
      font-size: $font-size;

      @media (max-width: $screen-md) {
        font-size: math.div($font-size, 1.2);
      }

      @media (max-width: $screen-xs) {
        font-size: math.div($font-size, $mid-ratio);
      }
    }
  }
}

@mixin disable-drag() {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@mixin ie-media-support() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin jtc-slider-arrows($__font-size: 80px) {
  .jtc-improved-slider-arrow {
    color: white;
  }
}

@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

@mixin galleryGridItemComponentContent() {
  .content {
    &.always-visible {
      opacity: 1;
    }

    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
    color: $color-white;
    z-index: 2;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 50px 24px 24px;
    background: linear-gradient(
                    to top,
                    rgba($color-black, 0.6),
                    rgba($color-black, 0)
    );
    transition: opacity 0.1s linear;
    @include big-heading($font-size__s);

    @media (min-width: $screen-md) {
      padding: 50px 24px 24px;
      opacity: 0;
    }

    .heading-title {
      display: flex;
      align-items: flex-end;
      margin: 0;
    }

    .icon {

      span {
        background-color: rgba(145, 145, 145, 0.8);
      }

      svg {
        transform: rotate(-45deg);

        path {
          stroke: $color-white;
        }
      }
    }

    .subtitles-shown-on-web-grid & {
      @media (min-width: $screen-md) {
        opacity: 1;
      }
    }

    .subtitles-hidden-on-mobile-grid & {
      @media (max-width: $screen-md) {
        opacity: 0;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

@mixin ctaGridItemComponentButtonWrapper() {
  .button-container {
    a,
    span.button-dummy {
      position: relative;
      z-index: 100;
      color: #ffffff;
      border: 1px solid #ffffff;
      background: transparent;
      font-size: 16px;
      min-height: 34px;
      padding: 8px 15px;
      font-weight: 500;
      border-radius: 0;
      transition: all 0.25s;
      line-height: 1.4em;
      text-transform: uppercase;

      span {
        width: 100%;
        display: inherit;
        align-items: inherit;
        justify-content: inherit;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }
}

@mixin textGridItemAlignTop() {
  &.alignment-top {
    justify-content: flex-start;
    padding: 0;

    @media (min-width: $screen-md) {
      padding: 0 $indent__base $indent__base;
    }

    .content {
      *:first-child {
        margin-top: 0;
      }
    }
  }
}


@mixin clipPathSection-01() {
  &:after {
    transform: translateZ(0);
    clip-path: polygon(74.487% 0.031%, 100% 0.537%, 100% 99.646%, 16.538% 100%, 0% 99.646%, 0% 0.537%, 74.487% 0.031%);
    @media (min-width: $screen-md) {
      clip-path: polygon(78.333% 0%, 100% 3.376%, 100% 96.624%, 9.615% 100%, 0% 96.624%, 0% 3.376%, 78.333% 0%);
    }
    @media (min-width: $screen-lg) {
      clip-path: polygon(78.334% 0%, 100% 2.754%, 100% 97.246%, 9.615% 100%, 0% 97.246%, 0% 2.754%, 78.334% 0%);
    }
  }
}

@mixin clipPathBox-01() {
  transform: translateZ(0);
  clip-path: polygon(0% 0%, 75.582% 1.639%, 99.814% 0%, 99.814% 98.108%, 85.692% 100%, 0% 98.108%, 0% 0%);
}

@mixin clipPathBox-02() {
  transform: translateZ(0);
  clip-path: polygon(1.517% 0%, 99.907% 0%, 99.907% 98.108%, 85.987% 100%, 1.517% 98.108%, 0.093% 83.607%, 1.517% 0%);
}

@mixin clipPathBox-03() {
  transform: translateZ(0);
  clip-path: polygon(0.988% 1.768%, 27.25% 0%, 100% 0.758%, 100% 100%, 0.186% 100%, 1.679% 18.813%, 0.988% 1.768%);
}

@mixin clipPathBox-04() {
  transform: translateZ(0);
  clip-path: polygon(0.802% 1.768%, 27.064% 0%, 99.814% 0.758%, 99.814% 100%, 23.883% 98.738%, 0% 100%, 0.802% 1.768%);
}

@mixin clipPathBox-05() {
  transform: translateZ(0);
  clip-path: polygon(0.802% 1.768%, 27.064% 0%, 99.814% 0.758%, 99.814% 100%, 23.883% 98.738%, 0% 100%, 0.802% 1.768%);
}

@import "../../../../styles/lib/variables";

.jtc-language-switcher-container {
  cursor: pointer;
  margin-left: 16px;
  text-align: right;

  &.is-mobile {
    margin-top: 92px;
  }

  @media (max-width: $screen-sm-max) {
    &:not(.is-mobile) {
      display: none;
    }
  }
  @media (min-width: $screen-md) {
    &.is-mobile {
      display: none;
    }
  }

  .label {
    line-height: 20px;
    color: $shades-dark__color;
    @media (min-width: $screen-md) {
      display: none;
    }
  }

  .flag-icon {
    img {
      display: block;
      height: 24px;
    }
  }

  .tag-as-button {
    border: 2px solid $color-green-light0;
    border-radius: 50px;
    padding: 6px 16px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    margin-right: 4px;
  }
}

@import "../../../../styles/lib/variables";
@import "../../../../styles/lib/utillities";

.yacht-details {
  .yacht-details-gallery-item {
    width: 300px;
    height: 260px;
    position: relative;
    margin-right: 16px;

    @include overlay-gradient(rgba(0, 0, 0, 0), 45%, rgba(0, 0, 0, 0.8), 100%);
  }
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

p {
  letter-spacing: 0.3px;
}

h1 {
  font-family: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 48px;
  line-height: 48px;
}
@media (min-width: 992px) {
  h1 {
    font-size: 60px;
    line-height: 60px;
  }
}
@media (min-width: 1200px) {
  h1 {
    font-size: 80px;
    line-height: 76px;
  }
}

h2 {
  font-family: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 40px;
  line-height: 40px;
}
@media (min-width: 992px) {
  h2 {
    font-size: 48px;
    line-height: 48px;
  }
}
@media (min-width: 1200px) {
  h2 {
    font-size: 60px;
    line-height: 56px;
  }
}

h3 {
  font-family: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.56px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 28px;
}
@media (min-width: 992px) {
  h3 {
    font-size: 32px;
    line-height: 32px;
  }
}
@media (min-width: 1200px) {
  h3 {
    font-size: 36px;
    line-height: 36px;
  }
}

h4 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 0.18px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
}
@media (min-width: 992px) {
  h4 {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (min-width: 1200px) {
  h4 {
    font-size: 24px;
    line-height: 28px;
  }
}

h5 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.16px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 20px;
}
@media (min-width: 992px) {
  h5 {
    font-size: 18px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  h5 {
    font-size: 20px;
    line-height: 24px;
  }
}

h6 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.28px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 992px) {
  h6 {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  h6 {
    font-size: 16px;
    line-height: 24px;
  }
}

.h6 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.28px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 992px) {
  .h6 {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  .h6 {
    font-size: 16px;
    line-height: 24px;
  }
}

.label, .abs-button-small, .button-small-active-count, .button-small-active, .button-small-tertiary, .button-small-tertiary-active, .button-small-secondary-light, .button-small-secondary, .button-small-primary, .button-small-error {
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

body {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #193537;
  line-height: 1.572;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  background-color: #F9F6F3 !important;
}

a {
  color: #193537;
  text-decoration: none;
  cursor: pointer;
}
p a {
  text-decoration: underline;
  font-weight: bold;
  color: inherit;
}

.container {
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .container {
    padding-left: 48px;
    padding-right: 48px;
  }
}
@media (min-width: 1200px) {
  .container {
    padding-left: 32px;
    padding-right: 32px;
  }
}
.container .container {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .container .container {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1200px) {
  .container .container {
    padding-left: 0;
    padding-right: 0;
  }
}

textarea {
  overflow: auto;
}

.clipPathSection-01:after {
  transform: translateZ(0);
  clip-path: polygon(74.487% 0.031%, 100% 0.537%, 100% 99.646%, 16.538% 100%, 0% 99.646%, 0% 0.537%, 74.487% 0.031%);
}
@media (min-width: 992px) {
  .clipPathSection-01:after {
    clip-path: polygon(78.333% 0%, 100% 3.376%, 100% 96.624%, 9.615% 100%, 0% 96.624%, 0% 3.376%, 78.333% 0%);
  }
}
@media (min-width: 1200px) {
  .clipPathSection-01:after {
    clip-path: polygon(78.334% 0%, 100% 2.754%, 100% 97.246%, 9.615% 100%, 0% 97.246%, 0% 2.754%, 78.334% 0%);
  }
}

.clipPathBox-01 {
  transform: translateZ(0);
  clip-path: polygon(0% 0%, 75.582% 1.639%, 99.814% 0%, 99.814% 98.108%, 85.692% 100%, 0% 98.108%, 0% 0%);
}

.clipPathBox-02 {
  transform: translateZ(0);
  clip-path: polygon(1.517% 0%, 99.907% 0%, 99.907% 98.108%, 85.987% 100%, 1.517% 98.108%, 0.093% 83.607%, 1.517% 0%);
}

.clipPathBox-03 {
  transform: translateZ(0);
  clip-path: polygon(0.988% 1.768%, 27.25% 0%, 100% 0.758%, 100% 100%, 0.186% 100%, 1.679% 18.813%, 0.988% 1.768%);
}

.clipPathBox-04 {
  transform: translateZ(0);
  clip-path: polygon(0.802% 1.768%, 27.064% 0%, 99.814% 0.758%, 99.814% 100%, 23.883% 98.738%, 0% 100%, 0.802% 1.768%);
}

.clipPathBox-05 {
  transform: translateZ(0);
  clip-path: polygon(0.802% 1.768%, 27.064% 0%, 99.814% 0.758%, 99.814% 100%, 23.883% 98.738%, 0% 100%, 0.802% 1.768%);
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

p {
  letter-spacing: 0.3px;
}

h1 {
  font-family: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 48px;
  line-height: 48px;
}
@media (min-width: 992px) {
  h1 {
    font-size: 60px;
    line-height: 60px;
  }
}
@media (min-width: 1200px) {
  h1 {
    font-size: 80px;
    line-height: 76px;
  }
}

h2 {
  font-family: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 40px;
  line-height: 40px;
}
@media (min-width: 992px) {
  h2 {
    font-size: 48px;
    line-height: 48px;
  }
}
@media (min-width: 1200px) {
  h2 {
    font-size: 60px;
    line-height: 56px;
  }
}

h3 {
  font-family: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.56px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 28px;
}
@media (min-width: 992px) {
  h3 {
    font-size: 32px;
    line-height: 32px;
  }
}
@media (min-width: 1200px) {
  h3 {
    font-size: 36px;
    line-height: 36px;
  }
}

h4 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 0.18px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
}
@media (min-width: 992px) {
  h4 {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (min-width: 1200px) {
  h4 {
    font-size: 24px;
    line-height: 28px;
  }
}

h5 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.16px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 20px;
}
@media (min-width: 992px) {
  h5 {
    font-size: 18px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  h5 {
    font-size: 20px;
    line-height: 24px;
  }
}

h6 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.28px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 992px) {
  h6 {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  h6 {
    font-size: 16px;
    line-height: 24px;
  }
}

.h6 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.28px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 992px) {
  .h6 {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  .h6 {
    font-size: 16px;
    line-height: 24px;
  }
}

.label, .abs-button-small, .button-small-active-count, .button-small-active, .button-small-tertiary, .button-small-tertiary-active, .button-small-secondary-light, .button-small-secondary, .button-small-primary, .button-small-error {
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

.img-fluid {
  display: block;
  max-width: 100%;
  height: auto;
}

@keyframes pulse {
  0% {
    opacity: 0.25;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.25;
  }
}
.jtc-table-wrapper {
  max-width: 100%;
  overflow: auto;
}

.jtc-table-title {
  color: #193537;
}

.jtc-table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  color: #193537;
}
.jtc-table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}
.jtc-table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.35em;
}
.jtc-table td,
.jtc-table th {
  padding: 0.625em;
  text-align: center;
}
.jtc-table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) {
  .jtc-table {
    border: 0;
  }
  .jtc-table caption {
    font-size: 1.3em;
  }
  .jtc-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    display: none;
  }
  .jtc-table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }
  .jtc-table tr:last-child {
    margin-bottom: 0;
  }
  .jtc-table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }
  .jtc-table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  .jtc-table td:last-child {
    border-bottom: 0;
  }
}

.content-table {
  border-collapse: collapse;
}
.content-table td,
.content-table th {
  border: 1px solid black;
}
.content-table tr:first-child th,
.content-table tr:first-child td {
  border-top: 0;
}
.content-table tr:last-child td {
  border-bottom: 0;
}
.content-table tr td:first-child,
.content-table tr th:first-child {
  border-left: 0;
}
.content-table tr td:last-child,
.content-table tr th:last-child {
  border-right: 0;
}
.content-table p {
  margin-top: 5px !important;
  margin-bottom: 5px;
}

/* Tooltip */
div[role=tooltip] {
  font-size: 12px;
}

@media (max-width: 767px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .hidden-md-down {
    display: none !important;
  }
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

p {
  letter-spacing: 0.3px;
}

h1 {
  font-family: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 48px;
  line-height: 48px;
}
@media (min-width: 992px) {
  h1 {
    font-size: 60px;
    line-height: 60px;
  }
}
@media (min-width: 1200px) {
  h1 {
    font-size: 80px;
    line-height: 76px;
  }
}

h2 {
  font-family: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 40px;
  line-height: 40px;
}
@media (min-width: 992px) {
  h2 {
    font-size: 48px;
    line-height: 48px;
  }
}
@media (min-width: 1200px) {
  h2 {
    font-size: 60px;
    line-height: 56px;
  }
}

h3 {
  font-family: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.56px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 28px;
}
@media (min-width: 992px) {
  h3 {
    font-size: 32px;
    line-height: 32px;
  }
}
@media (min-width: 1200px) {
  h3 {
    font-size: 36px;
    line-height: 36px;
  }
}

h4 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 0.18px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
}
@media (min-width: 992px) {
  h4 {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (min-width: 1200px) {
  h4 {
    font-size: 24px;
    line-height: 28px;
  }
}

h5 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.16px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 20px;
}
@media (min-width: 992px) {
  h5 {
    font-size: 18px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  h5 {
    font-size: 20px;
    line-height: 24px;
  }
}

h6 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.28px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 992px) {
  h6 {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  h6 {
    font-size: 16px;
    line-height: 24px;
  }
}

.h6 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.28px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 992px) {
  .h6 {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  .h6 {
    font-size: 16px;
    line-height: 24px;
  }
}

.label, .abs-button-small, .button-small-active-count, .button-small-active, .button-small-tertiary, .button-small-tertiary-active, .button-small-secondary-light, .button-small-secondary, .button-small-primary, .button-small-error {
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

.flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex.full-width {
  width: 100%;
}
.flex.full-width > div {
  width: 100%;
}
.flex.flex-row-nowrap {
  flex-flow: row nowrap;
}
.flex.flex-align-center {
  align-items: center;
}

.no-padding {
  padding: 0;
}

.no-margin-top {
  margin-top: 0;
}

.scroll-light {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
}

.scroll-dark {
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 50px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .jtc-datepicker-button-wrapper {
    height: 34px;
    width: 34px;
    align-items: none;
  }
}
.jtc-datepicker-button-wrapper button {
  right: -12px;
}

.jtc-input-label {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  letter-spacing: 0.96px;
}
.jtc-input-label .tooltip-wrapper-element {
  margin-left: 5px;
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

p {
  letter-spacing: 0.3px;
}

h1 {
  font-family: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 48px;
  line-height: 48px;
}
@media (min-width: 992px) {
  h1 {
    font-size: 60px;
    line-height: 60px;
  }
}
@media (min-width: 1200px) {
  h1 {
    font-size: 80px;
    line-height: 76px;
  }
}

h2 {
  font-family: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 40px;
  line-height: 40px;
}
@media (min-width: 992px) {
  h2 {
    font-size: 48px;
    line-height: 48px;
  }
}
@media (min-width: 1200px) {
  h2 {
    font-size: 60px;
    line-height: 56px;
  }
}

h3 {
  font-family: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.56px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 28px;
}
@media (min-width: 992px) {
  h3 {
    font-size: 32px;
    line-height: 32px;
  }
}
@media (min-width: 1200px) {
  h3 {
    font-size: 36px;
    line-height: 36px;
  }
}

h4 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 0.18px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
}
@media (min-width: 992px) {
  h4 {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (min-width: 1200px) {
  h4 {
    font-size: 24px;
    line-height: 28px;
  }
}

h5 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.16px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 20px;
}
@media (min-width: 992px) {
  h5 {
    font-size: 18px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  h5 {
    font-size: 20px;
    line-height: 24px;
  }
}

h6 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.28px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 992px) {
  h6 {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  h6 {
    font-size: 16px;
    line-height: 24px;
  }
}

.h6 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.28px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 992px) {
  .h6 {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  .h6 {
    font-size: 16px;
    line-height: 24px;
  }
}

.label, .abs-button-small, .button-small-active-count, .button-small-active, .button-small-tertiary, .button-small-tertiary-active, .button-small-secondary-light, .button-small-secondary, .button-small-primary, .button-small-error {
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

.abs-button-small, .button-small-active-count, .button-small-active, .button-small-tertiary, .button-small-tertiary-active, .button-small-secondary-light, .button-small-secondary, .button-small-primary, .button-small-error {
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  border-radius: 50px;
  backdrop-filter: blur(12px);
  white-space: nowrap;
}

.button-small-error {
  background: rgba(255, 85, 74, 0.24);
  color: #FF554A;
}

.button-small-primary {
  background: #47EDBC;
  color: #193537;
  box-shadow: 0 0 12px rgba(71, 237, 188, 0.5);
}

.button-small-secondary {
  background: rgba(169, 161, 154, 0.24);
  color: #193537;
}

.button-small-secondary-light {
  background: rgba(255, 255, 255, 0.16);
  color: #F9F6F3;
}

.button-small-tertiary, .button-small-tertiary-active {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-transform: initial;
  color: #F9F6F3;
  background-color: #193537;
  border: 2px solid #EAE3DC;
  padding: 16px 24px;
}

.button-small-tertiary-active {
  background-color: #47EDBC;
  color: #193537;
}

.button-small-active, .button-small-active-count {
  background-color: #FFD12F;
  box-shadow: 0 0 12px rgba(255, 209, 47, 0.5);
  color: #193537;
}

.button-small-active-count {
  padding: 5px;
}

.room-type .description {
  margin-top: 32px;
}
.room-type .room-type-gallery-item {
  width: 300px;
  height: 260px;
  position: relative;
  margin-right: 16px;
}
.room-type .room-type-gallery-item:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0.8) 100%);
}
.room-type .room-type-gallery-item span {
  position: absolute;
  left: 24px;
  bottom: 24px;
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
}
.room-type .jtc-component-itinerary-notice {
  margin-top: 32px;
}


.jtc-component-cms-component-renderer {
  position: relative;
}
.jtc-component-cms-component-renderer.flex.full-width > div.component-controls {
  width: auto;
}
.jtc-component-cms-component-renderer .component-controls {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
}
.jtc-component-cms-component-renderer .component-controls a {
  color: #47EDBC;
}
.jtc-component-cms-component-renderer:hover .component-controls {
  display: block;
}
.jtc-component-cms-component-renderer:hover .component-controls.gridcomponent, .jtc-component-cms-component-renderer:hover .component-controls.packlistcomponent {
  top: 40px;
}
.jtc-component-cms-component-renderer:hover .component-controls.herocomponent, .jtc-component-cms-component-renderer:hover .component-controls.tripherocomponent, .jtc-component-cms-component-renderer:hover .component-controls.tripfilterscomponent, .jtc-component-cms-component-renderer:hover .component-controls.teasercomponent {
  top: 120px;
}


@import "../../../styles/lib/variables";
@import "../../../styles/lib/utillities";

$__breakpoint: 1285px;

.jtc-component-footer-links {
  display: block;
  padding-top: 0;
  padding-bottom: 40px;
  background: $primary__color__medium;
  list-style: none;


  @media (min-width: $screen-md) {
    display: block;
  }

  .jtc-component-footer-links-cointainer {
    align-items: center;

    .regulations-and-impressum {
      padding: 0;
      margin: 0;

      @media (min-width: $__breakpoint) {
        align-items: center;
      }

      li {
        color: $color-white;
        padding: 0;
        margin-bottom: 16px;
        display: block;
        line-height: 14px;

        @media (min-width: $screen-xs) {
          display: inline-block;
          margin-bottom: 0;
          margin-right: 40px;
        }

        &.list-header {
          padding: 0;
          color: $color-yellow;

          @media (min-width: $screen-xs) {
            display: none;
          }

          h4 {
            font-size: 18px;
            margin-bottom: 16px;
          }
        }

        a {
          color: $color-white;
        }
      }
    }
  }
}

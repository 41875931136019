.jtc-component-cms-panel {
  position: fixed;
  bottom: 0px;
  left: 0px;
  padding: 10px;
  padding-right: 0;
  background: #fff;
  z-index: 100;
}
.jtc-component-cms-panel a {
  color: #47EDBC;
  margin-right: 10px;
}


@import "../../../../styles/lib/variables";
@import "../../../../styles/lib/utillities";

.hotel-details {
  .description {
    margin-top: 32px;
  }

  .hotel-details-gallery-item {
    width: 300px;
    height: 260px;
    position: relative;
    margin-right: 16px;

    @include overlay-gradient(rgba(0, 0, 0, 0), 45%, rgba(0, 0, 0, 0.8), 100%);

    span {
      position: absolute;
      left: 24px;
      bottom: 24px;
      color: white;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 1;
      font-weight: 700;
    }
  }

  .jtc-component-itinerary-notice {
    margin-top: 32px;
  }

  .jtc-dialog-yacht-modal__button {
    display: flex;
    justify-content: center;
  }

  .features {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
    margin-top: 32px;

    @media (min-width: $screen-md) {
      grid-template-columns: repeat(3, 1fr);
    }

    .feature {
      display: inline-flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;

      .feature-icon {
        font-size: 24px;
        width: 24px;
        height: 24px;
        line-height: 1;
      }

      .feature-description {
        margin-left: 12px;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.32px;
      }
    }
  }

  .location-and-furnishing {
    h6 {
      margin-bottom: 8px;
    }

    .location-and-furnishing-col {
      margin-top: 32px;

      .location-and-furnishing-description {
        > ul:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

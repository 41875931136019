.hidden-xs-down {
  @media (max-width: $screen-xs-max) {
    display: none !important;
  }
}

.hidden-sm-up {
  @media (min-width: $screen-sm-min) {
    display: none !important;
  }
}

.hidden-sm-down {
  @media (max-width: $screen-sm-max) {
    display: none !important;
  }
}

.hidden-md-up {
  @media (min-width: $screen-md-min) {
    display: none !important;
  }
}

.hidden-md-down {
  @media (max-width: $screen-md-min) {
    display: none !important;
  }
}

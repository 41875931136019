.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

p {
  letter-spacing: 0.3px;
}

h1 {
  font-family: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 48px;
  line-height: 48px;
}
@media (min-width: 992px) {
  h1 {
    font-size: 60px;
    line-height: 60px;
  }
}
@media (min-width: 1200px) {
  h1 {
    font-size: 80px;
    line-height: 76px;
  }
}

h2 {
  font-family: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 40px;
  line-height: 40px;
}
@media (min-width: 992px) {
  h2 {
    font-size: 48px;
    line-height: 48px;
  }
}
@media (min-width: 1200px) {
  h2 {
    font-size: 60px;
    line-height: 56px;
  }
}

h3 {
  font-family: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.56px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 28px;
}
@media (min-width: 992px) {
  h3 {
    font-size: 32px;
    line-height: 32px;
  }
}
@media (min-width: 1200px) {
  h3 {
    font-size: 36px;
    line-height: 36px;
  }
}

h4 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 0.18px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
}
@media (min-width: 992px) {
  h4 {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (min-width: 1200px) {
  h4 {
    font-size: 24px;
    line-height: 28px;
  }
}

h5 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.16px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 20px;
}
@media (min-width: 992px) {
  h5 {
    font-size: 18px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  h5 {
    font-size: 20px;
    line-height: 24px;
  }
}

h6 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.28px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 992px) {
  h6 {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  h6 {
    font-size: 16px;
    line-height: 24px;
  }
}

.h6 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.28px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 992px) {
  .h6 {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  .h6 {
    font-size: 16px;
    line-height: 24px;
  }
}

.label {
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

.jtc-component-jtc-gallery-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(25, 53, 55, 0.85);
  -webkit-transition: 0.5s;
  transition: all 0.3s linear;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.jtc-component-jtc-gallery-modal .modal-content {
  color: #fff;
  width: 100%;
  margin: auto;
  padding: 17px;
}
.jtc-component-jtc-gallery-modal .modal-content .modal-close {
  position: absolute;
  top: 17px;
  right: 17px;
  height: 30px;
  width: 30px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.5);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  z-index: 10;
  cursor: pointer;
}


@import "../../../styles/lib/utillities/mixins";

body {
  font-family: $font-family__base;
  font-size: $font-size__body;
  font-weight: $font-weight__medium;
  color: $primary__color;
  line-height: 1.572;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  background-color: $page__background-color !important;
}

a {
  color: $primary__color;
  text-decoration: none;
  cursor: pointer;

  p & {
    text-decoration: underline;
    font-weight: bold;
    color: inherit;
  }
}

.container {
  padding-left: $container-mobile__padding;
  padding-right: $container-mobile__padding;

  @media (min-width: $screen-md) and (max-width: $screen-lg) {
    padding-left: $container-tablet__padding;
    padding-right: $container-tablet__padding;
  }

  @media (min-width: $screen-lg) {
    padding-left: $container-desktop__padding;
    padding-right: $container-desktop__padding;
  }

  .container {
    padding-left: 0;
    padding-right: 0;

    @media (min-width: $screen-md) and (max-width: $screen-lg) {
      padding-left: 0;
      padding-right: 0;
    }

    @media (min-width: $screen-lg) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

textarea {
  overflow: auto;
}

.clipPathSection-01 {
  @include clipPathSection-01();
}

.clipPathBox-01 {
  @include clipPathBox-01();
}

.clipPathBox-02 {
  @include clipPathBox-02();
}

.clipPathBox-03 {
  @include clipPathBox-03();
}

.clipPathBox-04 {
  @include clipPathBox-04();
}

.clipPathBox-05 {
  @include clipPathBox-05();
}

.jtc-navigation-menu.item-children-wrapper {
  display: none;
  margin-top: 20px;
}
@media (min-width: 992px) {
  .jtc-navigation-menu.item-children-wrapper {
    position: absolute;
    padding-top: 44px;
    margin-top: 0;
    z-index: 1;
  }
  .jtc-navigation-menu.item-children-wrapper .jtc-user-navigation-scroll {
    border-radius: 5px;
    margin: 15px 0;
  }
  .jtc-navigation-menu.item-children-wrapper .jtc-user-navigation-scroll .item-children {
    margin: 0;
  }
}
.jtc-navigation-menu .item-children {
  list-style: none;
  padding: 0;
}
@media (min-width: 992px) {
  .jtc-navigation-menu .item-children {
    background: white;
    border-radius: 5px;
    padding: 30px 40px;
    box-shadow: 0 4px 40px 0 rgba(25, 53, 55, 0.08);
  }
}
.jtc-navigation-menu .item-children li {
  margin-bottom: 20px;
}
.jtc-navigation-menu .item-children li:last-child {
  margin-bottom: 0;
}


.jtc-component-not-found {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}
.jtc-component-not-found a {
  margin: 0 auto;
}


.jtc-component-scroll-top {
  position: fixed;
  display: none;
  bottom: 50px;
  right: 50px;
  height: 72px;
  width: 72px;
  background-color: #193537;
  border: 2px solid #325F61;
  border-radius: 20px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 1100;
  align-items: center;
  justify-content: center;
}
@media (min-width: 992px) {
  .jtc-component-scroll-top.scroller-visible {
    display: flex;
  }
}


@import "../../../../styles/lib/variables";

$navigation__color: $primary__color;
$navigation-first-level__color: $color-white;
$navigation-second-level__color: $shades-dark__color;
$navigation-customer__color: $primary__color;


.jtc-navigation-item-component {
  margin-bottom: 40px;
  @media (min-width: 992px) {
    margin-bottom: 0;
  }

  &.top-level {
    @media (max-width: 991px) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  a {
    display: flex;
    align-items: center;
    margin: 0;
    transition: color .1s ease-in-out;
  }

  &.isSpecial {
    @media (max-width: 991px) {
      grid-column-start: 2;
      grid-row-start: 1;
    }

    a {
      color: $navigation__color;

      > span {
        text-align: center;
      }
    }
  }

  &:not(.isSpecial) {
    a {
      color: $navigation-first-level__color;

      .isNavigationOpen &,
      .isProfileNavigationOpen & {
        color: $navigation__color;
      }
    }
  }

  .icon {
    display: flex;
    margin-left: 2px;

    @media (min-width: $screen-md) {
      display: none;
    }

    svg path {
      .customerArea &,
      .isNavigationOpen &,
      .isProfileNavigationOpen &,
      .with-color-scheme.grey & {
        stroke: $primary__color;
      }
    }
  }


  &.submenu-item {
    margin-bottom: 20px;

    a {
      color: $navigation-second-level__color;

      &:hover {
        color: $primary__color__lighter;
      }
    }

    .user-navigation-container-wrapper & {
      a {
        color: $navigation-customer__color;

        &:hover {
          color: $primary__color__lighter;
        }
      }
    }
  }

  &.isLast {
    order: 99;
  }

  &.isToggleItem {
    > .item-children-wrapper {
      display: block;
    }
  }

  @media (min-width: 992px) {
    &.hasChild {
      &:hover {
        > .item-children-wrapper {
          display: block;
        }
      }
    }
  }
}

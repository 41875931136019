.jtc-component-footer-sitemap {
  position: relative;
  display: block;
  background: #194648;
  padding-top: 80px;
  padding-bottom: 16px;
  margin-top: -30px;
}
@media (min-width: 576px) {
  .jtc-component-footer-sitemap {
    padding-bottom: 40px;
  }
}
.jtc-component-footer-sitemap .footer-logo {
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .jtc-component-footer-sitemap .footer-logo {
    margin-bottom: 64px;
  }
}
.jtc-component-footer-sitemap ul {
  padding: 0;
  background-color: transparent;
  list-style: none;
  flex: 1 1 auto;
  width: 100%;
  margin: 0;
}
@media (min-width: 576px) {
  .jtc-component-footer-sitemap ul {
    width: auto;
  }
}
.jtc-component-footer-sitemap ul li {
  color: #fff;
  padding: 0;
  margin-bottom: 16px;
}
.jtc-component-footer-sitemap ul li.list-header {
  padding: 0;
  color: #FFD12F;
}
.jtc-component-footer-sitemap ul li.list-header h4 {
  font-size: 18px;
  margin-bottom: 16px;
}
.jtc-component-footer-sitemap ul li a {
  color: #fff;
}
.jtc-component-footer-sitemap .container {
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
}
@media (min-width: 992px) {
  .jtc-component-footer-sitemap .container {
    flex-wrap: nowrap;
  }
}
.jtc-component-footer-sitemap .container.components {
  margin-bottom: 64px;
  gap: 64px;
}
.jtc-component-footer-sitemap .jtc-component-newsletter-signup-component {
  width: 100%;
  max-width: 432px;
  text-align: left;
}
.jtc-component-footer-sitemap .jtc-component-newsletter-signup-component .description {
  padding: 0;
  color: #FFD12F;
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: 800;
}
.jtc-component-footer-sitemap .jtc-social-media-component .title {
  padding: 0;
  color: #FFD12F;
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: 800;
  text-align: left;
}
.jtc-component-footer-sitemap .jtc-social-media-component .social-media-component-items {
  justify-content: flex-start;
}
.jtc-component-footer-sitemap .jtc-social-media-component .social-media-component-items .social-media-component-icon {
  background: rgba(255, 255, 255, 0.12);
}
.jtc-component-footer-sitemap .jtc-social-media-component .social-media-component-items .label {
  color: #fff;
}


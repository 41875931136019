.flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  &.full-width {
    width: 100%;

    > div {
      width: 100%;
    }
  }

  &.flex-row-nowrap {
    flex-flow: row nowrap;
  }

  &.flex-align-center {
    align-items: center;
  }
}

@import "../../../styles/lib/variables";
@import "../../../styles/lib/utillities/mixins";

.jtc-component-jtc-gallery-component {
  .BrainhubCarouselItem {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    align-self: center;

    img {
      display: block;
      max-height: calc(100vh - 350px);
      max-width: 99%;

      @include disable-drag;
      pointer-events: none;

      margin: 0 auto;
    }

    .user-profile-gallery-item {
      display: flex;
      flex-flow: column nowrap;
      height: calc(100vh - 140px);

      .image-wrapper {
        flex-grow: 1;
        width: 100%;
      }

      .item-description {
        align-self: flex-start;

        .user-name,
        .user-basic-info,
        .user-description {
          font-weight: $font-weight__light;
        }

        .user-basic-info {
          opacity: 0.8;
          margin-bottom: $indent__s;
        }

        .user-name {
          font-size: $font-size__l;

          .is-skipper-text {
            font-size: $font-size__m;
          }
        }

        .user-basic-info,
        .user-description {
          font-size: $font-size__s;
        }

        @media (min-width: $screen-md) {
          .user-name {
            font-size: $font-size__xl;

            .is-skipper-text {
              font-size: $font-size__l;
              opacity: 0.8;
            }
          }

          .user-basic-info,
          .user-description {
            font-size: 25px;
          }
        }

        .user-description {
          overflow: auto;
          max-height: 75px;

          @media (min-width: $screen-md) {
            max-height: 90px;
          }
        }
      }
    }

    .instagram-info {
      margin-bottom: 10px;

      .instagram {
        color: $color-white;
      }
    }
  }

  @include jtc-slider-arrows();

  button {
    visibility: hidden;
    pointer-events: none;

    @media (min-width: $screen-md) {
      visibility: visible;
      pointer-events: initial;
    }
  }
}

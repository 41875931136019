.group-by-month-year-heading {
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.08em;
  margin-bottom: 8px;
  margin-top: 16px;
  color: white;
  opacity: 0.6;
  display: block;
}

.group-by-month-year-heading_transparent {
  color: $primary__color;
}

.group-by-month-year-separator {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.08em;
  align-items: center;
  color: $shades-extra-light__color;
  margin-top: 20px;
  margin-bottom: 20px;

  &:before,
  &:after {
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 100px;
    display: block;
    content: "";
    height: 2px;
    width: calc(100% - 24px);
    background-color: $primary__color__lighter;
  }
}

.group-by-month-year-separator_transparent {
  color: $primary__color;
  opacity: 0.6;

  &:before,
  &:after {
    opacity: 0.6;
  }
}
